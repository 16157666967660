import React, { useState, useEffect } from "react";
import {
  Switch,
  Box,
  Typography,
  Skeleton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./style.css";
import { Input } from "../../common/input";
import { SelectInput } from "../../common/selectInput";
import Buttons from "../../common/button";
import { EmailType } from "./interface";

const options = [
  { text: "text", value: "text" },
  { text: "name", value: "name" },
];

const EmailSetup: React.FC = () => {
  const [sendTranscript, setSendTranscript] = useState<boolean>(false);
  const [supportEmails, setSupportEmails] = useState<EmailType[]>([
    { index: 0, emailType: "", emailAddress: "" },
  ]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 400);
    return () => clearTimeout(timer);
  }, []);

  const handleFieldType = () => {
    setSupportEmails((prevItems) => [
      ...prevItems,
      { index: prevItems.length, emailAddress: "email", emailType: "emailType" },
    ]);
  };

  const handleSelectChange =
    (index: number) => (event: React.ChangeEvent<{ value: unknown }>) => {
      const newFieldTypes = [...supportEmails];
      newFieldTypes[index].emailType = event.target.value as string;
      setSupportEmails(newFieldTypes);
    };

  const handleCancel = (id: number) => {
    setSupportEmails((prevItems) =>
      prevItems.filter((_ele, _eleIndex) => _eleIndex !== id)
    );
  };

  const handleToggle = () => {
    setSendTranscript(!sendTranscript);
  };

  const handleSave = (index: number) => {
    console.log("Support email:", supportEmails[index].emailType);
    console.log("Answer:", supportEmails[index].emailAddress);
  };

  return (
    <Box p={2} className="emailSetup">
      <Grid container sx={{ width: "80%" }}>
        <Grid size={12}>
          {loading ? (
            <Skeleton animation="wave" variant="text" width="60%" height={30} />
          ) : (
            <Typography sx={{ marginBottom: "1.5rem" }}>
              Set up email for transcript and support purposes
            </Typography>
          )}
        </Grid>
        <Grid size={12}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ marginBottom: "1.5rem" }}
          >
            <Box>
              {loading ? (
                <>
                  <Skeleton animation="wave" variant="text" width="40%" height={20} />
                  <Skeleton animation="wave" variant="text" width="60%" height={20} />
                </>
              ) : (
                <>
                  <Typography>Send Email Transcript</Typography>
                  <Typography variant="caption">
                    If enabled, we email users their respective chat transcript after
                    the chat has ended
                  </Typography>
                </>
              )}
            </Box>
            <Box>
              {loading ? (
                <Skeleton animation="wave" variant="rectangular" width={40} height={20} />
              ) : (
                <Switch checked={sendTranscript} onChange={handleToggle} />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid size={12} sx={{ marginBottom: "1.5rem" }}>
          {loading ? (
            <>
              <Skeleton animation="wave" variant="text" width="40%" height={20} />
              <Skeleton animation="wave" variant="text" width="60%" height={20} />
              <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
            </>
          ) : (
            <>
              <Typography>Main customer support email address</Typography>
              <Typography variant="caption">
                General email address for any case
              </Typography>
              <Input />
            </>
          )}
        </Grid>
        <Grid size={12}>
          {loading ? (
            <>
              <Skeleton animation="wave" variant="text" width="40%" height={20} />
              <Skeleton animation="wave" variant="text" width="60%" height={20} />
            </>
          ) : (
            <>
              <Typography>Add Support emails</Typography>
              <Typography variant="caption">
                Automatically redirect specific issue to relevant emails
              </Typography>
            </>
          )}
          <Grid size={12} sx={{ marginTop: "1rem" }}>
            {loading ? (
              <Skeleton animation="wave" variant="rectangular" width="100%" height={200} />
            ) : (
              <form noValidate autoComplete="off">
                {supportEmails.length > 0 && (
                  <Grid container spacing={2} marginBottom={"0.5rem"}>
                    {supportEmails.map((field, index) => (
                      <div key={index} className="formFieldsContainerBg">
                        <Grid size={12}>
                          <Grid container spacing={2}>
                            <Grid size={4}>
                              <SelectInput
                                placeholder="Select text"
                                value={field.emailType}
                                onChange={handleSelectChange(index)}
                                optionValue={options}
                                sx={{ height: "1.5rem" }}
                              />
                            </Grid>
                            <Grid size={8}>
                              <Input name={`name_${index}`} />
                            </Grid>
                          </Grid>
                          <Grid
                            size={12}
                            display={"flex"}
                            justifyContent={"flex-end"}
                            className="btnFlexEnd"
                          >
                            <Buttons
                              type="secondary"
                              text="Cancel"
                              sx={{ margin: "0.5rem 0", width: "100px" }}
                              onClick={() => handleCancel(index)}
                            />
                            <Buttons
                              type="primary"
                              text="Save"
                              sx={{ margin: "0.5rem 0", width: "100px" }}
                              onClick={() => handleSave(index)}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    ))}
                  </Grid>
                )}
                <Grid size={12} sx={{ margin: "0.875rem 0" }}>
                  <Buttons
                    type="primary"
                    start="addIcon"
                    text="Add new"
                    onClick={handleFieldType}
                    sx={{ margin: "0.5rem 0", width: "100px" }}
                  />
                </Grid>
              </form>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmailSetup;