import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid2";
import { SEARCHBAR } from "../../../common/search";
import { nanoid } from "nanoid";
import {
  Card,
  CardActions,
  CardContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  Skeleton,
  CardMedia,
  Box,
} from "@mui/material";
import { bot } from "../../../../assets/images";
import Buttons from "../../../common/button";
import "../style.css";

const jsonData = [
  {
    id: nanoid(),
    imrsrc: "https://botsonic.s3.amazonaws.com/integrations/WordPress.png",
    title: "Wordpress",
    para: "Embed Botsonic into your WordPress site for enhanced user interaction and AI chat capabilities, ensuring",
  },
  {
    id: nanoid(),
    imgsrc:
      "https://botsonic.s3.amazonaws.com/integrations/consent-agreement-integration-icon.svg",
    title: "Consent Agreement",
    para: "Initiate every Botsonic conversation with a customizable consent and privacy popup, ensuring",
  },
  {
    id: nanoid(),
    imgsrc:
      "https://static.vecteezy.com/system/resources/previews/017/221/839/original/telegram-logo-transparent-free-png.png",
    title: "Telegram",
    para: "Expand your Botsonic bot's reach and possibilities with seamless Telegram integration",
  },
  {
    id: nanoid(),
    imgsrc:
      "https://static.vecteezy.com/system/resources/previews/022/101/124/original/whatsapp-logo-transparent-free-png.png",
    title: "Whatsapp",
    para: "Extend your botsonic capabilities with Whatsapp",
  },
  {
    id: nanoid(),
    imgsrc: "https://botsonic.s3.amazonaws.com/integrations/zendesk_logo.png",
    title: "Zendesk ",
    para: "Effortlessly integrate your Zendesk support system inside Botsonic, enabling your customers to create support tickets directly.",
  },
  {
    id: nanoid(),
    imgsrc:
      "https://icon-library.com/images/facebook-messenger-icon-transparent/facebook-messenger-icon-transparent-5.jpg",
    title: "Messenger",
    para: "Boost your bot's functionalities, connect with users on Messenger, and deliver essential information.",
  },
  {
    id: nanoid(),
    imgsrc:
      "https://botsonic.s3.amazonaws.com/integrations/freshdesk_logo_1.png",
    title: "FreshDesk",
    para: "Effortlessly integrate your Freshdesk support system inside Botsonic, enabling your customers to create support tickets directly.",
  },
  {
    id: nanoid(),
    imgsrc: "https://botsonic.s3.amazonaws.com/integrations/gtm.png",
    title: "Google Tag Manager",
    para: "Track and analyze Botsonic bot interactions on your site with Google Analytics 4, using Google Tag Manager for precise event tracking.",
  },
  {
    id: nanoid(),
    imgsrc: "https://botsonic.s3.amazonaws.com/integrations/salesforce.svg",
    title: "Salesforce Ticketing System",
    para: "Effortlessly integrate your Salesforce support system inside Botsonic, enabling your customers to create support tickets directly.",
  },
  {
    id: nanoid(),
    imgsrc:
      "https://botsonic.s3.amazonaws.com/integrations/active_campaign.png",
    title: "ActiveCampaign",
    para: "Integrate ActiveCampaign with Botsonic to streamline your marketing automation and customer engagement",
  },
];
export const Integration: React.FC = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const getHeading = () => {
    switch (selectedIndex) {
      case 0:
        return "Your apps";
      case 1:
        return "All";
      case 2:
        return "Agent & Ticketing";
      case 3:
        return "Messaging Channels";
      case 4:
        return "More Apps";
      default:
        return "";
    }
  };

  return (
    <>
      <Grid container>
        <Grid size={8}>
          {loading ? (
            <Skeleton animation="wave" variant="text" width="60%" height={30} />
          ) : (
            <>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600",
                  letterSpacing: "-0.08399999886751175px",
                  color: "#000",
                  marginBottom: "6px",
                }}
              >
                All Integrations
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#61646B",
                  marginBottom: "6px",
                }}
              >
                Supercharge your assistant with ready-to-use integrations
              </Typography>
            </>
          )}
        </Grid>
        <Grid size={4}>
          {loading ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          ) : (
            <SEARCHBAR />
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid size={2}>
          <List>
            {loading ? (
              <>
                <ListItem disablePadding>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height={48}
                  />
                </ListItem>
                <ListItem disablePadding>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height={48}
                  />
                </ListItem>
                <ListItem disablePadding>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height={48}
                  />
                </ListItem>
                <ListItem disablePadding>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height={48}
                  />
                </ListItem>
                <ListItem disablePadding>
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height={48}
                  />
                </ListItem>
              </>
            ) : (
              <>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => setSelectedIndex(0)}
                    sx={{
                      background: selectedIndex === 0 ? " #F9F9FF" : "white",
                    }}
                  >
                    <ListItemText
                      className="assistantSubList"
                      primary="Your apps (0)"
                      sx={{
                        "& span": {
                          color: selectedIndex === 0 ? " #1D2024" : "#61646b",
                          fontWeight:
                            selectedIndex === 0 ? "600 !important" : "500",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem
                  sx={{
                    fontSize: "14px",
                    color: "#61646b",
                    fontWeight: "500",
                    fontFamily: "Inter",
                  }}
                >
                  Categories
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setSelectedIndex(1);
                    }}
                    sx={{
                      background: selectedIndex === 1 ? " #F9F9FF" : "white",
                    }}
                  >
                    <ListItemText
                      primary="All"
                      className="assistantSubList"
                      sx={{
                        "& span": {
                          color: selectedIndex === 1 ? " #1D2024" : "#61646b",
                          fontWeight:
                            selectedIndex === 1 ? "600 !important" : "500",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setSelectedIndex(2);
                    }}
                    sx={{
                      background: selectedIndex === 2 ? " #F9F9FF" : "white",
                    }}
                  >
                    <ListItemText
                      primary="Agent & Ticketing"
                      className="assistantSubList"
                      sx={{
                        "& span": {
                          color: selectedIndex === 2 ? " #1D2024" : "#61646b",
                          fontWeight:
                            selectedIndex === 2 ? "600 !important" : "500",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setSelectedIndex(3);
                    }}
                    sx={{
                      background: selectedIndex === 3 ? " #F9F9FF" : "white",
                    }}
                  >
                    <ListItemText
                      primary="Messaging Channels"
                      className="assistantSubList"
                      sx={{
                        "& span": {
                          color: selectedIndex === 3 ? " #1D2024" : "#61646b",
                          fontWeight:
                            selectedIndex === 3 ? "600 !important" : "500",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      setSelectedIndex(4);
                    }}
                    sx={{
                      background: selectedIndex === 4 ? " #F9F9FF" : "white",
                    }}
                  >
                    <ListItemText
                      primary="More Apps"
                      className="assistantSubList"
                      sx={{
                        "& span": {
                          color: selectedIndex === 4 ? " #1D2024" : "#61646b",
                          fontWeight:
                            selectedIndex === 4 ? "600 !important" : "500",
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              </>
            )}
          </List>
        </Grid>
        <Grid size={10} padding={"16px 20px"}>
          <Grid container size={12}>
            {loading ? (
              <Skeleton
                animation="wave"
                variant="text"
                width="40%"
                height={30}
              />
            ) : (
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "#000",
                  fontWeight: "500",
                  marginBottom: "15px",
                }}
              >
                {getHeading()} &nbsp;{" "}
                <span style={{ color: "#8b8d97" }}>({jsonData.length})</span>
              </Typography>
            )}
          </Grid>
          <Grid
            container spacing={4}
          >
            {jsonData.map((item) => (
        <Grid size={4} key={item.id}>
          <Box display="flex" flexDirection="column" height="100%">
            <Card style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
              {loading ? (
                <Skeleton variant="rectangular"  sx={{ width: 40, height: 40 }} />
              ) : (
                <CardMedia
                  component="img"
                  sx={{ width: 40, height: 40, marginLeft: '2rem' }}
                  image={item.imgsrc}
                  alt={item.title}
                />
              )}
              <CardContent style={{ flexGrow: 1 }}>
                {loading ? (
                  <>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </>
                ) : (
                  <>
                    <Typography gutterBottom variant="h5" component="div">
                      {item.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {item.para}
                    </Typography>
                  </>
                )}
              </CardContent>
            </Card>
          </Box>
        </Grid>
      ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
