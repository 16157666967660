import React, { useState, useEffect } from "react";
import {
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Skeleton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import Buttons from "../../../common/button";
import "../style.css";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../redux/features/alert/alertSlice";
import { Input } from "../../../common/input";

const htmlString = `  <!DOCTYPE html>
<html>
<head>
    <meta charset='utf-8'>
    <meta http-equiv='X-UA-Compatible' content='IE=edge'>
    <title>Page Title</title>
    <meta name='viewport' content='width=device-width, initial-scale=1'>
    <link rel='stylesheet' type='text/css' media='screen' href='main.css'>
    <script src="https://unpkg.com/react@18.2.0/umd/react.production.min.js"></script>
    <script src="https://unpkg.com/react-dom@18.2.0/umd/react-dom.production.min.js"></script>
  
    <!-- Material-UI Fonts and Icons -->
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
    />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
    />
    <script>
        window.api_key = "8bcaecfd328fc877a6cf871e97b59d5d";
        window.widget_id = "677f8c81fbf3a2cb3b41005b";
    </script>
</head>
<body>
    <div id="root"></div>
    <script src="https://virat-ai.s3.us-east-1.amazonaws.com/widget-app/build/static/js/main.146810e8.js"></script>   
</body>
</html>`;
export const Embed: React.FC = () => {
  const [alignment, setAlignment] = useState("web");
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  const copyText = async (text: any) => {
    try {
      await navigator.clipboard.writeText(text);
      dispatch(
        setAlert({
          open: true,
          alerType: "success",
          message: "Copied to clipboard",
        })
      );
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };

  return (
    <Grid container>
      <Grid size={12}>
        {loading ? (
          <Skeleton animation="wave" variant="text" width="60%" height={30} />
        ) : (
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "600",
              letterSpacing: "-0.08399999886751175px",
              color: "#000",
              marginBottom: "32px",
            }}
          >
            Configure your assistant according to your needs
          </Typography>
        )}
      </Grid>
      <Grid size={12} marginBottom={"15px"}>
        <Grid container>
          <Grid size={8}>
            {loading ? (
              <Skeleton animation="wave" variant="rectangular" width="70%" height={40} />
            ) : (
              <ToggleButtonGroup
                color="primary"
                value={alignment}
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                sx={{ width: "50%" }}
              >
                <ToggleButton value="web">Javascript</ToggleButton>
                {/* <ToggleButton value="android">iFrame</ToggleButton> */}
              </ToggleButtonGroup>
            )}
          </Grid>
          {/* <Grid size={4}>
            {loading ? (
              <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
            ) : (
              <Buttons
                type={"secondary"}
                text="Manage allowed hosts"
                sx={{ width: "100%" }}
              />
            )}
          </Grid> */}
        </Grid>
      </Grid>
      <Grid size={12} marginBottom={"15px"}>
        {loading ? (
          <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
        ) : (
          <div
            style={{
              background: "#f5bfbfa3",
              display: "flex",
              padding: "10px",
              fontSize: "14px",
              fontFamily: "Inter",
              alignContent: "center",
            }}
          >
            <InfoOutlinedIcon
              sx={{
                color: "red",
                verticalAlign: "middle",
                width: "15px",
                height: "15px",
                marginRight: "10px",
              }}
            />
            Please add allowed hosts for widget to work, it's mandatory for both
            javascript and iFrame.
          </div>
        )}
      </Grid>
      <Grid
        size={12}
        sx={{
          fontFamily: "Inter",
          fontSize: "12px",
          fontWeight: "500",
          color: " #61646B",
          marginBottom: "10px",
        }}
      >
        {loading ? (
          <Skeleton animation="wave" variant="text" width="80%" height={20} />
        ) : (
          "Paste the code snippet below in your HTML code where you want to display the Virat assistant."
        )}
      </Grid>
      <Grid size={12}>
        {loading ? (
          <Skeleton animation="wave" variant="rectangular" width="100%" height={100} />
        ) : (
          <div
            style={{
              background: "#d3d3d391",
              width: "100%",
              whiteSpace: "break-spaces",
              fontSize: "12px",
              position: "relative",
              padding: "10px",
            }}
          >
            <ContentCopyOutlinedIcon
              sx={{
                color: "#0000007d",
                cursor: "pointer",
                width: "20px",
                height: "20px",
                position: "absolute",
                right: "10px",
              }}
              onClick={() => copyText(htmlString)}
            />
            {htmlString}
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export const RestApi: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const copyText = async (text: any) => {
    try {
      await navigator.clipboard.writeText(text);
      dispatch(
        setAlert({
          open: true,
          alerType: "success",
          message: "Copied to clipboard",
        })
      );
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };

  return (
    <Grid container>
      <Grid size={12}>
        {loading ? (
          <Skeleton animation="wave" variant="text" width="60%" height={30} />
        ) : (
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "600",
              letterSpacing: "-0.08399999886751175px",
              color: "#000",
              marginBottom: "32px",
            }}
          >
            Find your unique API Endpoint and API Token below.
          </Typography>
        )}
      </Grid>
      <Grid size={12}>
        {loading ? (
          <Skeleton animation="wave" variant="text" width="40%" height={20} />
        ) : (
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "600",
              letterSpacing: "-0.08399999886751175px",
              color: "#000",
              marginBottom: "6px",
            }}
          >
            Generate Endpoint
          </Typography>
        )}
      </Grid>
      <Grid size={12} sx={{ marginBottom: "32px" }}>
        {loading ? (
          <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
        ) : (
          <Input />
        )}
      </Grid>
      <Grid size={12}>
        {loading ? (
          <Skeleton animation="wave" variant="text" width="40%" height={20} />
        ) : (
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "600",
              letterSpacing: "-0.08399999886751175px",
              color: "#000",
              marginBottom: "6px",
            }}
          >
            API Token
          </Typography>
        )}
      </Grid>
      <Grid size={12}>
        {loading ? (
          <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
        ) : (
          <Input
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <ContentCopyOutlinedIcon
                      sx={{
                        color: "#0000007d",
                        cursor: "pointer",
                        width: "20px",
                        height: "20px",
                        position: "absolute",
                        right: "10px",
                      }}
                      onClick={() => copyText("input")}
                    />
                  </InputAdornment>
                ),
              },
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};
