import React, { useState, useEffect } from "react";
import { Typography, Box, Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid";
import "./style.css";
import Buttons from "../../common/button";
const WorkingHours: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Box p={2}>
      <Grid container>
        <Grid item xs={12}>
          {loading ? (
            <Skeleton animation="wave" variant="text" width="40%" height={30} />
          ) : (
            <Typography>Working hours</Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          {loading ? (
            <>
              <Skeleton animation="wave" variant="text" width="80%" height={20} />
              <Skeleton animation="wave" variant="text" width="60%" height={20} />
            </>
          ) : (
            <Typography variant="caption">
              Give your customers a better idea of when to expect support by
              setting working hours. Create working hours with your business hours
              and time zones.
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={3} sx={{ mt: '1rem' }}>
            {loading ? (
              <Skeleton animation="wave" variant="rectangular" width="200px" height={40} />
            ) : (
              <Buttons type="primary" text="Create working hours" />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WorkingHours;
