import { Card, Typography, Chip, DialogActions, Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useState, useEffect } from "react";
import { ellipsis } from "../../../assets/icons";
import { bot } from "../../../assets/images";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Input } from "../../common/input";
import Button from "../../common/button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Page } from "../../../routes/config";
import {
  setAssistantIcon,
  setAssistantId,
  setAssistantName,
  setAssistantSidebar,
} from "../../../redux/features/assistant/assistantSlice";
import { SelectInput } from "../../common/selectInput";
import { useCreateBotMutation } from "../../../redux/services/botApiSlice";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { Loader } from "../../common/loader";
import { timeAgo } from "../../../utils";

export const AssistantModal = (props: any) => {
  const { botData, open, setOpen, setPagination, pagination } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the delay as needed
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCardClick = (item: any) => {
    dispatch(setAssistantSidebar(true));
    dispatch(setAssistantName(item?.name));
    dispatch(setAssistantId(item?.id));
    dispatch(setAssistantIcon(item?.icon_url));
    navigate(Page.SETTINGS, { state: { name: item?.name } });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ "& .MuiDialog-paper": { minHeight: "400px" } }}
      >
        <DialogTitle
          id="scroll-dialog-title"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <span> Your Assistants</span>
          <span>
            <ArrowBackIosIcon
              sx={{ width: "16px", height: "16px", cursor: "pointer" }}
              onClick={() => setPagination({ page: pagination?.page - 1 })}
            />
            <ArrowForwardIosIcon
              sx={{ width: "16px", height: "16px", cursor: "pointer" }}
              onClick={() => setPagination({ page: pagination?.page + 1 })}
            />
          </span>
        </DialogTitle>
        <DialogContent
          dividers={true}
          sx={{
            display: botData?.length === 0 ? "flex" : undefined,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {loading ? (
            <Skeleton variant="rectangular" width="100%" height={400} />
          ) : botData?.length === 0 ? (
            <div
              style={{
                color: "black",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              No data found
            </div>
          ) : (
            <Grid container spacing={2}>
              {botData.map((item: any) => (
                <Grid size={12} key={item.id}>
                  <Card
                    sx={{
                      padding: "16px",
                      borderRadius: "12px",
                      border: "1px solid #E5E7EB",
                      background: " #F9F9FA",
                      cursor: "pointer",
                    }}
                    onClick={() => handleCardClick(item)}
                  >
                    <Grid container justifyContent={"space-between"}>
                      <img alt="icon" src={item?.icon_url}></img>
                      <img src={ellipsis}></img>
                    </Grid>
                    <Grid container>
                      <Grid
                        size={12}
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "14px",
                          fontWeight: "600",
                          lineHeight: "20px",
                          letterSpacing: "-0.08399999886751175px",
                          color: "#111827",
                          marginTop: "12px",
                        }}
                      >
                        {item?.name}
                      </Grid>
                      <Grid
                        size={12}
                        sx={{
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontWeight: "500",
                          color: "#8B8D97",
                          marginTop: "12px",
                        }}
                      >
                        Last updated {timeAgo(item?.date_updated)}
                      </Grid>
                      <Grid
                        size={12}
                        marginTop={3}
                        display="flex"
                        justifyContent={"space-between"}
                      >
                        <Typography
                          sx={{
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: "500",
                            color: "#61646B",
                            alignContent: "center",
                          }}
                        >
                          {item?.total_sessions} chats in 3 days
                        </Typography>
                        <Chip
                          sx={{
                            background: " #7770e11a",
                            borderRadius: "4px",
                            fontFamily: "Inter",
                            fontSize: "12px",
                            fontWeight: "500",
                            color: " #7A72E1",
                          }}
                          label={"General Assistant"}
                        ></Chip>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export const CreateDialog = (props: any) => {
  const { open, setOpen, inputRef } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [createBot, createBotInfo] = useCreateBotMutation<any>();

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateBot: any = () => {
    createBot({ name: value });
  };

  useEffect(() => {
    if (createBotInfo?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: createBotInfo?.data?.message,
        })
      );
      dispatch(setAssistantSidebar(true));
      dispatch(setAssistantName(createBotInfo?.data?.name));
      dispatch(setAssistantId(createBotInfo?.data?.chatbot_id));
      navigate(Page.SETTINGS, { state: { name: createBotInfo?.data?.name } });
    }
  }, [createBotInfo?.isSuccess]);

  const handleInputChange = (e: any) => {
    setValue(e.target.value);
  };

  const options = [
    {
      text: "gpt-4o-mini",
      value: "gpt-4o-mini",
    },
    {
      text: "gt-4o",
      value: "gt-4o",
      disabled: true,
    },
    {
      text: "gemini",
      value: "gemini",
      disabled: true,
    },
    {
      text: "phy 3.1",
      value: "phy 3.1",
      disabled: true,
    },
  ];

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-titl"
        aria-describedby="scroll-dialog-descriptio"
      >
        <DialogTitle
          id="scroll-dialog-titl"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          New Bot
        </DialogTitle>
        <DialogContent dividers={true}>
          <Grid container>
            <Grid
              size={12}
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "600",
                letterSpacing: "-0.08399999886751175px",
                color: "#000",
                marginBottom: "6px",
                marginTop: "10px",
              }}
            >
              {loading ? (
                <Skeleton variant="text" width="100%" height={40} />
              ) : (
                "What would you like to name your assistant?"
              )}
            </Grid>
            <Grid size={12}>
              {loading ? (
                <Skeleton variant="rectangular" width="100%" height={56} />
              ) : (
                <Input
                  inputRef={inputRef}
                  value={value}
                  onChange={handleInputChange}
                  helperText={value === "" ? "Please enter bot name" : ""}
                />
              )}
            </Grid>
            <Grid
              size={12}
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "600",
                letterSpacing: "-0.08399999886751175px",
                color: "#000",
                marginBottom: "6px",
                marginTop: "10px",
              }}
            >
              Model
            </Grid>
            <Grid size={12}>
              <SelectInput
                placeholder={"Select AI Model"}
                fullWidth
                value={"gpt-4o-mini"}
                optionValue={options}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid size={2}>
            <Button
              onClick={handleClose}
              type={"secondary"}
              text="Cancel"
            ></Button>
          </Grid>
          <Grid size={3}>
            <Button
              onClick={handleCreateBot}
              type="primary"
              text="Create Assistant"
              disabled={value === null || value === ""}
            ></Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};
