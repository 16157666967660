import { List, ListItem, ListItemButton, ListItemText, Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useState, useEffect } from "react";
import "../style.css";
import { Embed, RestApi } from "./embed";

export const Connect = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Grid container>
      <Grid size={3}>
        <List>
          {loading ? (
            <>
              <ListItem disablePadding>
                <Skeleton animation="wave" variant="rectangular" width="100%" height={48} />
              </ListItem>
              <ListItem disablePadding>
                <Skeleton animation="wave" variant="rectangular" width="100%" height={48} />
              </ListItem>
            </>
          ) : (
            <>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => setSelectedIndex(0)}
                  sx={{ background: selectedIndex === 0 ? " #F9F9FF" : "white" }}
                >
                  <ListItemText
                    className="assistantSubList"
                    primary="Embed on your website"
                    sx={{
                      "& span": {
                        color: selectedIndex === 0 ? " #1D2024" : "#61646b",
                        fontWeight: selectedIndex === 0 ? "600 !important" : "500",
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
              {/* <ListItem disablePadding>
                <ListItemButton
                  onClick={() => setSelectedIndex(1)}
                  sx={{ background: selectedIndex === 1 ? " #F9F9FF" : "white" }}
                >
                  <ListItemText
                    primary="Rest API"
                    className="assistantSubList"
                    sx={{
                      "& span": {
                        color: selectedIndex === 1 ? " #1D2024" : "#61646b",
                        fontWeight: selectedIndex === 1 ? "600 !important" : "500",
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem> */}
            </>
          )}
        </List>
      </Grid>
      <Grid size={7}>
        {loading ? (
          <Skeleton animation="wave" variant="rectangular" width="100%" height={400} />
        ) : (
          <>
            {selectedIndex === 0 && <Embed />}
            {selectedIndex === 1 && <RestApi />}
          </>
        )}
      </Grid>
    </Grid>
  );
};