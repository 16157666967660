import { Backdrop, CircularProgress } from "@mui/material";
export const Loader = (props: any) => {
  const { loader, setLoader } = props;

  return (
    <div>
      <Backdrop
        sx={{
          color: "#7d2491",
          backgroundColor: "#e3e2e2b3",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};
