import { apiRoutes } from "../../config/api";
import { apiSlice } from "./apiSlice";

export const botApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder: any) => ({
    getAllBots: builder.query({
      query: (params: any) => {
        return {
          url: `${apiRoutes.BOT}`,
          method: "GET",
          params: params,
        };
      },
    }),
    createBot: builder.mutation({
      query: (data: any) => {
        return {
          url: `${apiRoutes?.CREATEBOT}`,
          method: "POST",
          body: data,
        };
      },
    }),
    getDataSouces: builder.query({
      query: (params: any) => {
        return {
          url: `${apiRoutes.DATASOURCES}`,
          method: "GET",
          //   params: params,
        };
      },
    }),
    uploadFile: builder.mutation({
      query: (data: any) => {
        return {
          url: `${apiRoutes?.UPLOAD}`,
          method: "POST",
          body: data,
        };
      },
    }),
    trainBot: builder.mutation({
      query: (data: any) => {
        return {
          url: `${apiRoutes?.TRAINBOT}`,
          method: "POST",
          body: data,
        };
      },
    }),
    deleteBot: builder.mutation({
      query: (data: any) => {
        const { id, ...rest } = data;
        return {
          url: `${apiRoutes?.DELETEBOT}/${id}`,
          method: "PATCH",
          body: rest,
        };
      },
    }),
    changeBotStatus: builder.mutation({
      query: (data: any) => {
        const { id, ...rest } = data;
        return {
          url: `${apiRoutes?.STATUS}`,
          method: "PATCH",
          body: rest,
        };
      },
    }),
    getWidgets: builder.query({
      query: (params: any) => {
        return {
          url: `${apiRoutes.WIDGET}`,
          method: "GET",
          //   params: params,
        };
      },
    }),
    getFiles: builder.query({
      query: (params: any) => {
        return {
          url: `/chatbot/${params?.id}/files`,
          method: "GET",
          //   params: params,
        };
      },
    }),
    updateBot: builder.mutation({
      query: (data: any) => {
        const { id, ...rest } = data;
        return {
          url: `${apiRoutes?.UPDATEBOT}/${id}`,
          method: "PUT",
          body: rest,
        };
      },
    }),
    updateAppearance: builder.mutation({
      query: (data: any) => {
        return {
          url: `${apiRoutes?.UPDATEAPPEARANCE}`,
          method: "POST",
          body: data,
        };
      },
    }),
    getOneBot: builder.query({
      query: (params: any) => {
        return {
          url: `${apiRoutes?.GETBOT}/${params?.id}`,
          method: "GET",
          //   params: params,
        };
      },
    }),
    getAppearance: builder.mutation({
      query: (data: any) => {
        return {
          url: `${apiRoutes?.GETAPPEARANCE}`,
          method: "POST",
          body: data,
        };
      },
    }),
    updateWelcomeMessage: builder.mutation({
      query: (data: any) => {
        return {
          url: `${apiRoutes?.WELCOMEMESSAGE}`,
          method: "POST",
          body: data,
        };
      },
    }),
    getWelcomeMessage: builder.query({
      query: (params: any) => {
        return {
          url: `${apiRoutes?.WELCOMEMESSAGE}`,
          method: "GET",
          params: params,
        };
      },
    }),
    updateStarterQuestion: builder.mutation({
      query: (data: any) => {
        return {
          url: `${apiRoutes?.STARTERQUESTION}`,
          method: "POST",
          body: data,
        };
      },
    }),
    getStarterQuestion: builder.query({
      query: (params: any) => {
        return {
          url: `${apiRoutes?.STARTERQUESTION}`,
          method: "GET",
          params: params,
        };
      },
    }),
  }),
});

export const {
  useGetAllBotsQuery,
  useCreateBotMutation,
  useGetDataSoucesQuery,
  useUploadFileMutation,
  useTrainBotMutation,
  useDeleteBotMutation,
  useChangeBotStatusMutation,
  useGetWidgetsQuery,
  useGetFilesQuery,
  useUpdateBotMutation,
  useUpdateAppearanceMutation,
  useGetOneBotQuery,
  useGetAppearanceMutation,
  useUpdateWelcomeMessageMutation,
  useGetWelcomeMessageQuery,
  useUpdateStarterQuestionMutation,
  useGetStarterQuestionQuery,
} = botApiSlice;
