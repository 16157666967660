import { Container, Typography, Box } from "@mui/material";
import { LoginHeader } from "../../layout/loginHeader";
import Grid from "@mui/material/Grid2";
import { email } from "../../../assets/images";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useEffect, useState } from "react";
import Buttons from "../../common/button";
import { useLocation, useNavigate } from "react-router-dom";
import { handleErrors, maskEmail } from "../../../utils";
import {
  useResendOtpMutation,
  useVerifyOtpMutation,
} from "../../../redux/services/loginApiSlice";
import { Page } from "../../../routes/config";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { Loader } from "../../common/loader";
import dayjs from "dayjs";

export const VerifyOtp = () => {
  const location = useLocation();

  const state = location?.state;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(60);
  const [isDisabled, setIsDisabled] = useState(true);

  const [verifyOtp, verifyOtpInfo] = useVerifyOtpMutation<any>();
  const [resendOtp, resendOtpInfo] = useResendOtpMutation<any>();

  const handleChange = (newValue: any) => {
    setOtp(newValue);
  };
  useEffect(() => {
    // Start the timer when the component mounts
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1); // Decrement timer by 1 every second
      }, 1000);

      // Cleanup interval on component unmount
      return () => clearInterval(interval);
    } else {
      setIsDisabled(false);
    }
  }, [timer]);
  const handleResend = () => {
    resendOtp({ email: state?.email });
  };

  const handleVerify = () => {
    verifyOtp({ email: state?.email, otp: otp });
  };
  useEffect(() => {
    if (verifyOtpInfo?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "User Registered successfully",
        })
      );
      navigate(Page.LOGIN);
    }
  }, [verifyOtpInfo?.isSuccess]);
  useEffect(() => {
    if (resendOtpInfo?.isSuccess) {
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "OTP sent successfully",
        })
      );
      setTimer(60);
      setIsDisabled(true);
    }
  }, [resendOtpInfo?.isSuccess]);
  useEffect(() => {
    if (verifyOtpInfo?.isError) {
      handleErrors(verifyOtpInfo, dispatch);
    }
  }, [verifyOtpInfo?.isError]);
  useEffect(() => {
    if (resendOtpInfo?.isError) {
      handleErrors(resendOtpInfo, dispatch);
    }
  }, [resendOtpInfo?.isError]);
  return (
    <>
      {/* <Loader loader={resendOtpInfo?.isLoading || verifyOtpInfo?.isLoading} /> */}
      <Grid container sx={{ height: "100vh" }}>
        <Grid size={12}>
          <LoginHeader />
        </Grid>
        <Grid
          size={12}
          sx={{
            overflow: "auto",
            height: "100%",
            marginTop: "8px",
          }}
        >
          <Grid container spacing={4} height={"100%"}>
            {/* Left Section */}
            <Grid
              size={{ xs: 12, md: 5, xl: 7 }}
              sx={{
                background: "#F9F9FF",
                padding: "130px 114px",
                paddingBottom: "60px",
              }}
            >
              <Typography className="otpHead">Verify Your Email</Typography>
              <Typography className="otpSubHead">
                An OTP has been sent to your email
              </Typography>
              <Typography className="email">
                {maskEmail(state?.email)}
              </Typography>
              {/* <Box
                component="img"
                src={email}
                alt="Robot"
                sx={{
                  width: "400px",
                  height: "400px",
                  display: "flex",
                  justifySelf: "center",
                }}
              /> */}
            </Grid>

            {/* Right Section */}
            <Grid size={{ xs: 12, md: 7, xl: 5 }}>
              <Grid container justifyContent={"center"} height={"100%"}>
                <Grid
                  size={{ xs: 12 }}
                  alignContent={"center"}
                  padding={"0px 18%"}
                >
                  <Box
                    sx={{
                      textAlign: "left",
                    }}
                  >
                    <Typography className="signIn">Verification</Typography>
                    <Typography className="otpText">Enter OTP</Typography>
                    <MuiOtpInput
                      value={otp}
                      onChange={handleChange}
                      length={6}
                    />
                    <Typography
                      className={isDisabled ? "disableResend" : "resend"}
                      onClick={isDisabled ? () => {} : handleResend}
                    >
                      Resend OTP{" "}
                      <span className="time">
                        ({" "}
                        {timer > 0
                          ? `00:${timer.toString().padStart(2, "0")}`
                          : "00:00"}
                        )
                      </span>
                    </Typography>
                    <Buttons
                      variant="contained"
                      type="primary"
                      sx={{
                        marginTop: "54px",
                        marginBottom: "100px",
                      }}
                      text={"Verify"}
                      onClick={handleVerify}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 60px",
              position: "absolute",
              bottom: "0px",
              width: "100%",
              backgroundColor: "white",
              boxShadow: "0px 1px 2px 0px #0000004D",
            }}
          >
            <span className="bookmark">
              © {dayjs().format("YYYY")} Virat
            </span>
            <span className="bookmark">
              About &nbsp;&nbsp; Support &nbsp;&nbsp; Contact Us
            </span>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
