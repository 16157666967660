import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Link, Typography } from "@mui/material";

interface BackLinkProps {
  to: string;
  label: string;
}

export const BackLink: React.FC<BackLinkProps> = ({ to, label }) => {
  return (
    <Link
      component={RouterLink}
      to={to}
      underline="none"
      color="var(--bot-text-colo)"
    >
      <Typography className="heading">{label}</Typography>
    </Link>
  );
};
