import {
  Paper,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import Buttons from "../../../common/button";
import "../style.css";
import { SEARCHBAR } from "../../../common/search";
import ReactMarkdown from "react-markdown";
import { ContentCopyOutlined } from "@mui/icons-material";
import {
  useGetChatHistoryMutation,
  useGetChatSessionMutation,
} from "../../../../redux/services/chatApiSlice";
import { useSelector } from "react-redux";
import { timeAgo } from "../../../../utils";
import { Loader } from "../../../common/loader";
import dayjs from "dayjs";
export const Inbox = () => {
  const chatbotId = useSelector((state: any) => state?.assistant?.id);
  const icon = useSelector((state: any) => state?.assistant?.icon);

  const [alignment, setAlignment] = useState("chats");
  const [message, setMessage] = useState([]);
  const [selectedSession, setSelectedSession] = useState<any>();
  const [sessionList, setSessionList] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 400);
    return () => clearTimeout(timer);
  }, []);

  const [getChatSession, getChatSessionInfo] = useGetChatSessionMutation<any>();
  const [getChatHistory, getChatHistoryInfo] = useGetChatHistoryMutation<any>();

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  useEffect(() => {
    getChatSession({ chatbot_id: chatbotId });
  }, []);

  useEffect(() => {
    if (getChatSessionInfo?.isSuccess) {
      setSelectedSession(getChatSessionInfo?.data?.sessions[0]);
      setSessionList(getChatSessionInfo?.data?.sessions);
    }
  }, [getChatSessionInfo?.isSuccess]);

  useEffect(() => {
    if (selectedSession) {
      getChatHistory({
        chatbot_id: chatbotId,
        session_id: selectedSession?._id,
      });
    }
  }, [selectedSession]);

  useEffect(() => {
    if (getChatHistoryInfo?.isSuccess) {
      setMessage(getChatHistoryInfo?.data?.["chat-history"]);
    }
  }, [getChatHistoryInfo?.isSuccess]);

  return (
    <>
      <Grid
        container
        sx={{
          borderBottom: "1px solid #E5E7EB",
          marginLeft: "-32px",
          marginRight: "-32px",
          padding: "0px 32px 18px",
          marginTop: "-13px",
        }}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Grid size={3}>
          {getChatSessionInfo?.isLoading ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height={40}
            />
          ) : (
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
              sx={{ width: "50%", height: "35px" }}
            >
              <ToggleButton value="chats">Chats</ToggleButton>
              {/* <ToggleButton value="users">Users</ToggleButton> */}
            </ToggleButtonGroup>
          )}
        </Grid>
        {/* <Grid size={2}>
          {loading ? (
            <Skeleton variant="rectangular" width="100%" height={40} />
          ) : (
            <Buttons type="secondary" text={"Export all data"} />
          )}
        </Grid> */}
      </Grid>
      <Grid container height={"92%"}>
        <Grid size={3}>
          <Grid container padding={"16px 10px 16px 0px"}>
            <Grid size={12} sx={{ marginBottom: "15px" }}>
              {getChatSessionInfo?.isLoading ? (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width="100%"
                  height={40}
                />
              ) : (
                <SEARCHBAR />
              )}
            </Grid>
            {getChatSessionInfo?.isLoading
              ? Array.from(new Array(5)).map((_, index) => (
                  <Grid key={index} size={12} padding={"16px 10px 16px 10px"}>
                    <Skeleton
                      animation="wave"
                      variant="rectangular"
                      width="100%"
                      height={60}
                    />
                  </Grid>
                ))
              : sessionList?.map((session: any) => (
                  <Grid
                    key={session._id}
                    size={12}
                    padding={"16px 10px 16px 10px"}
                    borderLeft={
                      session?._id === selectedSession?._id
                        ? "5px solid #705cf6"
                        : undefined
                    }
                    sx={{
                      cursor: "pointer",
                      background:
                        session?._id === selectedSession?._id
                          ? "#7a72e11c"
                          : undefined,
                    }}
                    onClick={() => setSelectedSession(session)}
                  >
                    <Typography
                      className="heading"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      Unknown User
                      <span style={{ fontSize: "12px", color: "#8b8d97" }}>
                        {timeAgo(session?.date_created)}
                      </span>
                    </Typography>
                    <Typography className="subHeadingText">
                      {session?.title.replace(/"/g, "")}
                    </Typography>
                  </Grid>
                ))}
          </Grid>
        </Grid>
        <Grid size={6} padding={"16px"} sx={{ background: "#80808024" }}>
          <Paper sx={{ height: "100%" }}>
            <Grid container>
              <Grid
                size={12}
                padding={"10px"}
                display={"flex"}
                justifyContent={"space-between"}
                borderBottom={"1px solid #80808054"}
              >
                {getChatSessionInfo?.isLoading ? (
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="100%"
                    height={40}
                  />
                ) : (
                  <>
                    <Typography className="heading">Unknown User</Typography>
                    <Typography sx={{ fontSize: "14px", color: "red" }}>
                      Block IP
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid
                size={12}
                padding={"10px"}
                overflow={"auto"}
                maxHeight={"500px"}
              >
                {getChatSessionInfo?.isLoading
                  ? Array.from(new Array(5)).map((_, index) => (
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        width="100%"
                        height={60}
                        sx={{ marginBottom: "10px" }}
                      />
                    ))
                  : message.map((msg: any, index: any) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent:
                            msg.role === "user" ? "flex-end" : "flex-start",
                          marginBottom: "10px",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            maxWidth: msg.role === "user" ? "70%" : "76%",
                            padding: "0px 15px",
                            borderBottomRightRadius:
                              msg.role === "user" ? "0px" : "10px",
                            borderBottomLeftRadius:
                              msg.role === "bot" ? "0px" : "10px",
                            textAlign: "left",
                            background:
                              msg.role === "user" ? "#d5d5d540" : undefined,
                            borderRadius:
                              msg.role === "user" ? "10px" : undefined,
                            paddingLeft: "15px",
                          }}
                          className={
                            msg.role === "user" || message.length - 1 === index
                              ? ""
                              : "hoverClass"
                          }
                        >
                          <p
                            style={{
                              margin: 0,
                              color:
                                msg.role === "user" ? "#888888" : "#000000",
                              fontSize: "18px",
                              fontWeight: 400,
                              fontFamily: "Poppins",
                            }}
                            className={msg.role === "user" ? "markPara" : ""}
                          >
                            <ReactMarkdown>{msg.message}</ReactMarkdown>
                          </p>
                        </div>
                        <img style={{ height: "26px" }} src={icon} alt="icon" />
                      </div>
                    ))}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid size={3} sx={{ background: "#80808024", padding: "16px" }}>
          <Grid container>
            <Grid size={12}>
              <Paper>
                <Grid container padding={"10px"}>
                  <Grid
                    size={12}
                    display={"flex"}
                    justifyContent={"space-between"}
                    borderBottom={"1px solid #80808054"}
                  >
                    {getChatSessionInfo?.isLoading ? (
                      <Skeleton
                        animation="wave"
                        variant="text"
                        width="100%"
                        height={40}
                      />
                    ) : (
                      <Typography className="heading">User Info</Typography>
                    )}
                  </Grid>
                  <Grid size={12}>
                    {getChatSessionInfo?.isLoading ? (
                      <Skeleton
                        animation="wave"
                        variant="text"
                        width="100%"
                        height={40}
                      />
                    ) : (
                      <Typography className="subHeadingText">
                        IP address
                      </Typography>
                    )}
                    {getChatSessionInfo?.isLoading ? (
                      <Skeleton
                        animation="wave"
                        variant="text"
                        width="100%"
                        height={40}
                      />
                    ) : (
                      <>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "14px",
                            marginTop: "5px",
                          }}
                        >
                          49.43.169.77{" "}
                          <ContentCopyOutlined
                            sx={{ cursor: "pointer", height: "14px" }}
                          />
                        </Typography>
                      </>
                    )}
                  </Grid>
                  <Grid size={12} marginTop={"10px"}>
                    {getChatSessionInfo?.isLoading ? (
                      <Skeleton
                        animation="wave"
                        variant="text"
                        width="100%"
                        height={40}
                      />
                    ) : (
                      <>
                        <Typography className="subHeadingText">
                          Chat ID
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontSize: "14px",
                            marginTop: "5px",
                          }}
                        >
                          49.43.169.77{" "}
                          <ContentCopyOutlined
                            sx={{ cursor: "pointer", height: "14px" }}
                          />
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            {getChatSessionInfo?.isLoading ? (
                      <Skeleton
                        animation="wave"
                        variant="text"
                        width="100%"
                        height={200}
                      />
                    ) : (
                      <Grid size={12} marginTop={"20px"}>
                      <Paper>
                        <Grid container>
                          <Grid
                            size={12}
                            padding={"10px"}
                            display={"flex"}
                            justifyContent={"space-between"}
                            borderBottom={"1px solid #80808054"}
                          >
                            <Typography className="heading">Interaction</Typography>
                          </Grid>
                          <Grid size={12} padding={"10px"}>
                            <Typography className="subHeadingText">
                              Recorded at
                            </Typography>
                            <Typography sx={{ fontSize: "14px", marginTop: "5px" }}>
                              {dayjs(
                                selectedSession?.date_created,
                                "DD-MM-YYYY HH:mm:ss"
                              ).format("HH:mm DD MMMM YYYY")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                    )}
           
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
