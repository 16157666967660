import { Files as FileComponent } from "./files";
import { Apps as AppComponent } from "./apps";
import { Links as LinkComponent } from "./links";
import { FAQs as FaqComponent } from "./faq";
import { SpreadSheets as SpreadsheetComponent } from "./spreadsheet";
export const Files = () => <FileComponent />;
export const Apps = () => <AppComponent />;
export const Links = () => <LinkComponent />;
export const FAQs = () => <FaqComponent />;
export const SpreadSheets = () => <SpreadsheetComponent />;
