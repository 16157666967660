import "./style.css";
import { Avatar, Drawer, Fab, Skeleton } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import { useEffect, useState } from "react";
import Buttons from "../common/button";
import { useGetAppearanceMutation } from "../../redux/services/botApiSlice";
import { Loader } from "../common/loader";
import { TestBot } from "./testBot";
import { useLocation } from "react-router-dom";
import { Page } from "../../routes/config";
import { BackLink } from "../pages/assistant/actions/backLinks/BackLink";

export const UiHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState<any>(false);
  const headingName = useSelector((state: any) => state?.auth?.header);
  const sidebarState = useSelector((state: any) => state?.assistant?.create);
  const chatbotId = useSelector((state: any) => state?.assistant?.id);
  const chatbotName = useSelector((state: any) => state?.assistant?.name);

  const [botDetail, setBotDetail] = useState<any>();
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [getAppearance, getAppearanceInfo] = useGetAppearanceMutation<any>();

  const handlePlaygroundClick = () => {
    const chatState = {
      chatBotId: chatbotId,
      chatBotName: chatbotName,
      form: false,
    };
    const serializedState = encodeURIComponent(JSON.stringify(chatState));
    const chatUrl = `${window.location.origin}/bot/chat?state=${serializedState}`;
    window.open(chatUrl, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    if (getAppearanceInfo?.isSuccess) {
      setBotDetail(getAppearanceInfo?.data);
      setOpen(true);
      setIsChatWindowOpen(true);
    }
  }, [getAppearanceInfo?.isSuccess]);

  useEffect(() => {
    // Simulate loading time
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }, []);

  const handleTestBotClick = () => {
    getAppearance({ chatbot_id: chatbotId });
  };

  return (
    <Grid
      container
      sx={{ padding: "22px 32px", borderBottom: "1px solid #E5E7EB" }}
    >
      {/* <Loader loader={getAppearanceInfo?.isLoading} /> */}

      <Grid size={{ xs: 6 }} className="heading">
        {loading ? (
          <Skeleton animation="wave" variant="text" width="60%" height={40} />
        ) : location?.pathname !== Page.CREATE_ACTION ? (
          headingName
        ) : (
          <BackLink to={Page.CREATE_ACTION} label="Back" />
        )}
      </Grid>

      <Grid size={{ xs: 6 }}>
        <Grid container justifyContent={"right"} spacing={1.5}>
          {sidebarState ? (
            <>
              <Grid size={3}>
            
                  <Buttons
                    type={"secondary"}
                    text="Playground"
                    onClick={handlePlaygroundClick}
                  />
              </Grid>
              <Grid size={3}>
           
                  <Buttons
                    type="primary"
                    text="Test bot"
                    onClick={handleTestBotClick}
                  />
              </Grid>
              {open && (
                <>
                  <TestBot
                    chatbotId={chatbotId}
                    widget={botDetail}
                    isOpen={isChatWindowOpen}
                    setIsOpen={setIsChatWindowOpen}
                    setTestBotOpen={setOpen}
                  />

                  <Fab
                    aria-label="add"
                    sx={{
                      position: "fixed",
                      bottom: "16px",
                      right: "16px",
                      backgroundColor: botDetail?.accent_color,
                      "&:hover": {
                        backgroundColor: botDetail?.accent_color,
                      },
                    }}
                    onClick={() => setIsChatWindowOpen(!isChatWindowOpen)}
                  >
                    <img src={botDetail?.icon_url} alt="Bot Icon" />
                  </Fab>
                </>
              )}
            </>
          ) : (
            <Grid size={3}>
              {loading ? (
                <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
              ) : (
                <Buttons
                  type="tertiary"
                  variant="outlined"
                  text={"watch tutorial"}
                />
              )}
            </Grid>
          )}
          <Grid size={1}>
            {loading ? (
              <Skeleton animation="wave" variant="circular" width={32} height={32} />
            ) : (
              <Avatar alt={"A"} sx={{ width: "32px", height: "32px" }} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
