import { IconButton, SvgIcon } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

export const CircleIcon: React.FC = () => {
  return (
    <IconButton
      sx={{
        borderRadius: '50%',
        border: '2px solid',
        padding: '8px',
        color: 'var(--bot-color-progress-dark)',
        borderColor: 'var(--bot-color-progress-dark)',
      }}
    >
      <SvgIcon>
        <InfoIcon />
      </SvgIcon>
    </IconButton>
  );
};
