import { BaseUrls } from "../types";

const baseUrls: BaseUrls | any = {
  local: " http://127.0.0.1:8000/api",
  dev: "https://app.0918.in/api",
  prod: "",
};

export const ENV: string = process.env.REACT_APP_RUNNING_ENV
  ? process.env.REACT_APP_RUNNING_ENV
  : "dev";

export const baseURL = baseUrls[ENV];

export enum apiRoutes {
  LOGIN = "/auth/login",
  REGISTER = "/auth/register",
  VERIFYOTP = "/auth/verify_otp",
  RESENDOTP = "/auth/resend_otp",
  BOT = "/chatbot/list",
  CREATEBOT = "/chatbot/create",
  DATASOURCES = "/chatbot/data_sources",
  UPLOAD = "/chatbot/upload",
  TRAINBOT = "/chatbot/train",
  CHAT = "/chatbot/chat",
  CHATHISTORY = "/chatbot/chat-history",
  CHATSESSION = "/chatbot/chats/list",
  CREATECHATSESSION = "/chatbot/chat-session/create",
  DELETEBOT = "/chatbot/delete",
  STATUS = "/chatbot/status",
  DELETECHATSESSION = "/chatbot/chat-session/delete",
  WIDGET = "/chatbot/widgets/icons",
  UPDATEBOT = "/chatbot/update",
  UPDATEAPPEARANCE = "/chatbot/widgets/update",
  GETAPPEARANCE = "/chatbot/widgets/get",
  GETUSAGE = "/chatbot/usage-analysis",
  GETWIDGET = "/chatbot/widgets",
  GETBOT = "/chatbot/get",
  WELCOMEMESSAGE = "/chatbot/welcome-message",
  STARTERQUESTION = "/chatbot/starter-questions",
  CHATSESSIONANALYTIC = "/chatbot/analytics/chat-sessions",
}
