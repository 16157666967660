import dayjs from "dayjs";
import { setAlert } from "../redux/features/alert/alertSlice";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";

export const maskEmail = (email: string) => {
  if (email !== undefined && email !== null) {
    const [localPart, domain] = email?.split("@");
    const firstTwo = localPart?.substring(0, 2);
    const middleLength = localPart?.length - 3;
    const lastChar = localPart?.slice(-1);
    const masked = firstTwo + "*".repeat(middleLength) + lastChar;
    return `${masked}@${domain}`;
  } else return "";
};
export const filterMessages = (data: any) => {
  let showMessages = false;

  return data.filter((item: any) => {
    // If the message exists, toggle `showMessages` to true
    if (item.message.trim()) {
      showMessages = true;
    }
    // Return true for all objects after the first valid message
    return showMessages;
  });
};
export const handleErrors = (apiData: any, dispatch: any) => {
  if (apiData?.isError) {
    if (apiData?.error?.data && apiData?.error?.data?.message) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: apiData?.error?.data?.message,
        })
      );
    } else if (apiData?.error?.data && apiData?.error?.data?.detail) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: apiData?.error?.data?.detail,
        })
      );
    } else {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: "Unexpected error exists, please try after some time",
        })
      );
    }
  }
};
dayjs.extend(customParseFormat);
export const groupSessionsByMonth = (sessions: any[]) => {
  const groupedData: any = {};

  sessions.forEach((session) => {
    // Format the date to dd-mm-yyyy
    const formattedDate = dayjs(
      session.date_created,
      "DD-MM-YYYY HH:mm:ss"
    ).format("DD-MM-YYYY");
    const monthYear = dayjs(
      session.date_created,
      "DD-MM-YYYY HH:mm:ss",
      true
    ).format("MMMM YYYY");

    if (!groupedData[monthYear]) {
      groupedData[monthYear] = [];
    }

    // Add session to the corresponding month group
    groupedData[monthYear].push({
      ...session,
    });
  });

  // Convert the grouped data into an array of objects for easy mapping
  return Object.entries(groupedData).map(([month, sessions]) => ({
    month,
    sessions,
  }));
};
dayjs.extend(relativeTime);

export const timeAgo = (apiTime: any) => {
  // Convert API timestamp (DD-MM-YYYY HH:mm:ss) to Day.js object
  const formattedDate = dayjs(apiTime, "DD-MM-YYYY HH:mm:ss");

  // Calculate relative time from now
  return formattedDate.fromNow();
};
