import React, { ChangeEvent, useState, useEffect, useMemo, useCallback } from "react";
import Grid from "@mui/material/Grid2";
import { BackLink } from "../backLinks/BackLink";
import { CircleIcon } from "../iIcon";
import { TabPanel } from "./tabPanel";
import {
  FormControl,
  FormControlLabel,
  Typography,
  Switch,
  Box,
  Skeleton,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material";
import { Input } from "../../../../common/input";
import { SelectInput } from "../../../../common/selectInput";
import Buttons from "../../../../common/button";
import DeleteIcon from "@mui/icons-material/Delete";
import { Table } from "../../../../common/table";
import { testDataRows, testDataCols, UrlMethod } from "../../interface";

export const CreateAction = () => {
  const options = [
    { text: "String", value: "String" },
    { text: "Boolean", value: "Boolean" },
    { text: "Number", value: "Number" },
  ];
  let uniqueId = 1;
  const generateId = (): number => {
    return uniqueId++;
  };
  const rowsData: testDataRows[] = [
    {
      id: generateId(),
      action: "  ",
    },
  ];
    const initialRows: testDataRows[] = [{ id: generateId(), action: "  " }];
    const [rowData, setRowData] = useState<testDataRows[]>(initialRows);
    const [optionalInput, setOptionalInput] = useState<boolean>(true);
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState(0);
    const [rows, setRows] = useState<UrlMethod[]>([
      { index: generateId(), url: "", method: "String" },
    ]);
    const [gatherRows, setGatherRows] = useState<UrlMethod[]>([
      { index: generateId(), url: "", method: "String" },
    ]);
  
  
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };
  
    const handleOptionInput = (event: ChangeEvent<HTMLInputElement>) => {
      setOptionalInput(event.target.checked);
    };
  
    const columns: testDataCols[] = [
      {
        field: "action",
        headerName: "No test calls made",
        minWidth: 600,
        headerAlign: "left",
        sortable: false,
        cellClassName: "cell-padding",
        flex: 1,
      },
    ];
  
    const handleAddRow = useCallback(() => {
      setRows((prevRows) => [
        ...prevRows,
        { index: generateId(), url: "", method: "String" },
      ]);
    }, []);
    const handleGatherAddRow = useCallback(() => {
      setGatherRows((prevRows) => [
        ...prevRows,
        { index: generateId(), url: "", method: "String" },
      ]);
    }, []);
  
    const handleDeleteRow = useCallback((index: number) => {
      setRows((prevRows) => prevRows.filter((_, i) => i !== index));
    }, []);
  
    
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <>
      <Grid container>
        <Grid size={9} sx={{paddingLeft:'10rem'}}>
          <Grid
            container
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
          >
            <Grid size={1}>
              <CircleIcon />
            </Grid>
            <Grid size={11}>
              {loading ? (
                <>
                  <Skeleton animation="wave" variant="text" width="80%" />
                  <Skeleton animation="wave" variant="text" width="60%" />
                  <Skeleton animation="wave" variant="rectangular" height={40} />
                  <Skeleton animation="wave" variant="text" width="80%" />
                  <Skeleton animation="wave" variant="rectangular" height={80} />
                  <Skeleton animation="wave" variant="text" width="80%" />
                  <Skeleton animation="wave" variant="text" width="60%" />
                </>
              ) : (
                <>
                  <Typography>
                    Basic information <sup>*</sup>
                  </Typography>
                  <Typography variant="caption">
                    Update the description and upload sources to help the bot
                    better understand when to use this ability.
                  </Typography>
                  <Typography variant="caption">Name</Typography>
                  <Input />
                  <Grid size={12}>
                    <Typography>Description</Typography>
                    <Input multiline={true} minRows={4} fullWidth />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          <hr style={{ border: "1px solid #ddd", margin: "50px 0 30px" }} />
          <Grid
            container
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
          >
            <Grid size={1}>
              <CircleIcon />
            </Grid>
            <Grid size={11}>
              <Grid container>
                <Grid size={12}>
                  {loading ? (
                    <>
                      <Skeleton animation="wave" variant="text" width="80%" />
                      <Skeleton animation="wave" variant="text" width="60%" />
                      <Skeleton animation="wave" variant="rectangular" height={40} />
                      <Skeleton animation="wave" variant="text" width="80%" />
                      <Skeleton animation="wave" variant="rectangular" height={80} />
                      <Skeleton animation="wave" variant="text" width="80%" />
                      <Skeleton animation="wave" variant="text" width="60%" />
                    </>
                  ) : (
                    <>
                      <Typography>
                        Gather inputs from chat (Optional)
                      </Typography>
                      <Typography variant="body2">
                        Specify the information the bot should look for in the
                        conversation to make this action's API call
                      </Typography>
                      <Grid size={1} sx={{ padding: "20px 0 10px" }}>
                        <Buttons
                          type="primary"
                          start="addIcon"
                          text="Add"
                          onClick={handleGatherAddRow} 
                          sx={{ margin: "0.5rem 0", width: "100px" }}
                        />
                      </Grid>
                      {gatherRows.map((row, index) => (
                      <Grid container spacing={2} key={row.index}>
                        <Grid
                          size={12}
                          display={"flex"}
                          justifyContent={"space-around"}
                          marginBottom={'1rem'}
                        >
                          <Grid size={4}>
                            <FormControl fullWidth>
                              <Box
                                display="flex"
                                flexDirection="column"
                                gap={1}
                              >
                                <Typography variant="body1">
                                  Data type
                                </Typography>
                                <SelectInput
                                  placeholder={"String"}
                                  fullWidth
                                  value={"String"}
                                  optionValue={options}
                                />
                              </Box>
                            </FormControl>
                          </Grid>
                          <Grid size={5}>
                            <Typography> Input</Typography>
                            <Grid size={12}>
                              <Input />
                            </Grid>
                          </Grid>
                          <Grid
                            size={3}
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Grid>
                              <FormControl fullWidth margin="normal">
                                <FormControlLabel
                                  className="settingFormLable"
                                  control={
                                    <Switch
                                      checked={optionalInput}
                                      onChange={handleOptionInput}
                                    />
                                  }
                                  label={
                                    <>
                                      <Typography>Optional</Typography>
                                    </>
                                  }
                                />
                              </FormControl>
                            </Grid>
                            <Grid sx={{ paddingTop: "0.75rem" }}>
                              <DeleteIcon />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      ))}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <hr style={{ border: "1px solid #ddd", margin: "50px 0 30px" }} />
          <Grid
            container
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
          >
            <Grid size={1}>
              <CircleIcon />
            </Grid>
            <Grid size={11}>
              <Grid container>
                <Grid size={12}>
                  {loading ? (
                    <>
                      <Skeleton animation="wave" variant="text" width="80%" />
                      <Skeleton animation="wave" variant="text" width="60%" />
                      <Skeleton animation="wave" variant="rectangular" height={40} />
                      <Skeleton animation="wave" variant="text" width="80%" />
                      <Skeleton animation="wave" variant="rectangular" height={80} />
                      <Skeleton animation="wave" variant="text" width="80%" />
                      <Skeleton animation="wave" variant="text" width="60%" />
                    </>
                  ) : (
                    <>
                      <Typography>Make Custom API</Typography>
                      <Typography variant="body2">
                        Build the API call for this Action, including inputs
                        from chat, headers and authentication
                      </Typography>
                      <Grid container>
                        <Grid size={12}>
                          <Box sx={{ width: "100%" }}>
                            <Tabs
                              value={value}
                              onChange={handleTabChange}
                              aria-label="simple tabs example"
                            >
                              <Tab label="Endpoints" {...a11yProps(0)} />
                              <Tab label="Headers" {...a11yProps(1)} />
                            </Tabs>
                            <TabPanel value={value} index={0}>.
                            </TabPanel>
                            <TabPanel value={value} index={1}>.
                            </TabPanel>
                          </Box>
                        </Grid>
                      </Grid>
                      {rows.map((row, index) => (
                      <Grid container spacing={4} key={row.index}>
                        <Grid
                          size={12}
                          display={"flex"}
                          justifyContent={"space-around"}
                          marginTop={"1rem"}
                        >
                          <Grid size={4}>
                            <Typography variant="body1">URL</Typography>
                            <Input />
                          </Grid>
                          <Grid size={5}>
                            <Typography> Method</Typography>
                            <Grid size={12}>
                              <FormControl fullWidth>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  gap={1}
                                >
                                  <SelectInput
                                    placeholder={"String"}
                                    fullWidth
                                    value={"String"}
                                    optionValue={options}
                                  />
                                </Box>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <Grid
                            size={3}
                            display={"flex"}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                          >
                            <Grid sx={{ paddingTop: "0.75rem" }}>
                            <IconButton onClick={() => handleDeleteRow(index)}>
                              <DeleteIcon />
                            </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                        </Grid>
                      ))}
                      <Grid container spacing={4}>
                        <Grid
                          size={3}
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Grid size={1} sx={{ padding: "0 0 10px" }}>
                            <Buttons
                              type="primary"
                              start="addIcon"
                              text="Add"
                              onClick={handleAddRow}
                              sx={{ margin: "0.5rem 0", width: "100px" }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <hr style={{ border: "1px solid #ddd", margin: "50px 0 30px" }} />
          <Grid
            container
            display={"flex"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
          >
            <Grid size={1}>
              <CircleIcon />
            </Grid>
            <Grid size={11}>
              <Grid container>
                <Grid size={12}>
                  {loading ? (
                    <>
                      <Skeleton animation="wave" variant="text" width="80%" />
                      <Skeleton animation="wave" variant="text" width="60%" />
                      <Skeleton animation="wave" variant="rectangular" height={40} />
                      <Skeleton animation="wave" variant="text" width="80%" />
                      <Skeleton animation="wave" variant="rectangular" height={80} />
                      <Skeleton animation="wave" variant="text" width="80%" />
                      <Skeleton animation="wave" variant="text" width="60%" />
                    </>
                  ) : (
                    <>
                      <Typography>Test API response</Typography>
                      <Typography variant="body2" sx={{ padding: "0.5rem 0" }}>
                        Get a sample response, with test values for any
                        placeholders in the API call.
                      </Typography>

                      <Grid container spacing={4}>
                        <Grid
                          size={3}
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Grid size={6} sx={{ padding: "0 0 10px" }}>
                            <Buttons
                              type="primary"
                              start="addIcon"
                              text="Save and Test"
                              // onClick={handleOptionRowInput}
                              sx={{ margin: "0.5rem 0", width: "150px" }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid size={12} sx={{ marginTop: "1.25rem" }}>
                          <Table
                            rows={rowData}
                            columns={columns}
                            isPagination={false}
                            rowCount={0}
                            sx={{
                              "& .MuiDataGrid-row:nth-of-type(even)": {
                                backgroundColor: "transparent",
                              },
                            }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
