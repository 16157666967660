import React, { useEffect, useState } from "react";
import { Card, Skeleton, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useGetDataSoucesQuery } from "../../../../../redux/services/botApiSlice";

export const Apps: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [dataSources, setDataSources] = useState<any>([]);
  const { data, isSuccess, isLoading, isError, refetch }: any =
    useGetDataSoucesQuery({});

  useEffect(() => {
    if (isSuccess) {
      setDataSources(data?.message?.[0]?.data_sources);
    }
  }, [isSuccess]);

  useEffect(() => {
    // Simulate a data fetch
    const timer = setTimeout(() => {
      setLoading(false);
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  return (
    <Grid container sx={{ padding: "20px" }}>
      <Grid size={12} marginBottom={"20px"}>
        {isLoading ? (
          <Skeleton animation="wave" variant="text" width="100%" height={40} />
        ) : (
          <Typography
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "600",
              letterSpacing: "-0.08399999886751175px",
              color: "#000",
              marginBottom: "6px",
            }}
          >
            Import files with ease and ensure they are on sync
          </Typography>
        )}
      </Grid>
      <Grid size={12} marginBottom={2} marginTop={2}>
        <Grid container spacing={2}>
          {isLoading ? (
            <>
              {Array(4)
                .fill(null)
                .map((_: any, index: any) => (
                  <Grid size={3}>
                    <Skeleton
                      key={index} // Always use a unique key when mapping
                      animation="wave"
                      variant="text"
                      width="100%"
                      height={150}
                    />
                  </Grid>
                ))}
            </>
          ) : (
            <>
              {dataSources.map((data: any, index: any) => (
                <Grid size={3}>
                  <Card
                    key={index}
                    className="trainingCard"
                    sx={{
                      background: "#fff",
                      position: "relative",
                    }}
                  >
                    <div className="ribbon">Upcoming</div>
                    <div style={{ display: "grid" }}>
                      <span style={{ textAlign: "center", marginTop: "12px" }}>
                        <img
                          src={data?.image_url}
                          style={{ width: "45px", height: "45px" }}
                        ></img>
                      </span>
                      <span
                        style={{ color: "#000" }}
                        className="smallCardTitle"
                      >
                        {data?.data_source_name}
                      </span>
                    </div>
                  </Card>
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
