import React, { ChangeEvent } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { RowData, EditComponentProps } from "../interface";

export const EditRow: React.FC<EditComponentProps> = ({ open, onClose, onSave, editRow, setEditRow }) => (
  <Dialog
    open={open}
    onClose={onClose}
    sx={{
      '& .MuiDialog-paper': {
        width: '100%',
        maxWidth: '800px', 
        borderRadius: '1rem'
      },
    }}
  >
    <DialogTitle>Edit Row</DialogTitle>
    <DialogContent>
      <TextField
        autoFocus
        margin="dense"
        label="Action"
        type="text"
        fullWidth
        variant="standard"
        value={editRow?.action || ""}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setEditRow({ ...editRow, action: e.target.value } as RowData)}
      />
      <FormControl fullWidth margin="dense" variant="standard">
        <InputLabel>Modified By</InputLabel>
        <Select
          value={editRow?.modifiedBy || ""}
          onChange={(e: SelectChangeEvent<unknown>) => setEditRow({ ...editRow, modifiedBy: e.target.value as string } as RowData)}
        >
          <MenuItem value="Custom API">Custom API</MenuItem>
          <MenuItem value="Knowledge Base">Knowledge Base</MenuItem>
        </Select>
      </FormControl>
      <TextField
        margin="dense"
        label="Last Modified"
        type="date"
        fullWidth
        variant="standard"
        value={editRow?.lastModified || ""}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setEditRow({ ...editRow, lastModified: e.target.value } as RowData)}
      />
    </DialogContent>
    <DialogActions sx={{ padding: '1rem' }}>
      <Button onClick={onClose}>Cancel</Button>
      <Button onClick={onSave}>Save</Button>
    </DialogActions>
  </Dialog>
);