import { apiRoutes } from "../../config/api";
// import { apiEndPoints } from "../../constants/enum";
import { apiSlice } from "./apiSlice";

export const analyticsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder: any) => ({
    getUsage: builder.query({
      query: (params: any) => {
        return {
          url: `${apiRoutes.GETUSAGE}`,
          method: "GET",
          //   params: params,
        };
      },
    }),
    getChatSesionAnalytic: builder.query({
      query: (params: any) => {
        return {
          url: `${apiRoutes.CHATSESSIONANALYTIC}`,
          method: "GET",
          //   params: params,
        };
      },
    }),
  }),
});

export const { useGetUsageQuery, useGetChatSesionAnalyticQuery } =
  analyticsApiSlice;
