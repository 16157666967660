import React, { ChangeEvent, useState } from "react";
import { Input } from "../../../common/input";
import { SelectInput } from "../../../common/selectInput";
import Grid from "@mui/material/Grid2";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  Typography,
  Skeleton,
  SelectChangeEvent,
  InputLabel,
  Box,
} from "@mui/material";
import { RowData } from "../interface";
import Buttons from "../../../common/button";

interface AddRowProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  newRow: RowData;
  setNewRow: React.Dispatch<React.SetStateAction<RowData>>;
  actionError: string | null;
  setActionError: React.Dispatch<React.SetStateAction<string | null>>;
  loading: boolean; // Add loading prop
}
export const AddRow: React.FC<AddRowProps> = ({
  open,
  onClose,
  onSave,
  newRow,
  actionError,
  setActionError,
  setNewRow,
  loading, // Destructure loading prop
}) => {
  const options = [
    {
      text: "Custom API",
      value: "Custom API",
    },
    {
      text: "Knowledge Base",
      value: "Knowledge Base",
    },
  ];

  const handleActionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setNewRow({ ...newRow, action: value });
    if (value.trim() === "") {
      setActionError("Action name cannot be empty");
    } else if (value.length < 3) {
      setActionError("Action name must be at least 3 characters");
    } else {
      setActionError(null);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "100%",
          maxWidth: "800px",
          borderRadius: "1rem",
        },
      }}
    >
      <DialogTitle>Create Action</DialogTitle>
      <Typography
        variant="body2"
        sx={{ padding: "0 1.45rem", fontWeight: 600, fontSize: "14px" }}
      >
        Let your AI Agent take action on behalf of customers
      </Typography>
      <DialogContent>
        {loading ? (
          <>
            <Skeleton animation="wave" variant="text" width="80%" />
            <Skeleton animation="wave" variant="text" width="60%" />
            <Skeleton animation="wave" variant="rectangular" height={40} />
            <Skeleton animation="wave" variant="text" width="80%" />
            <Skeleton animation="wave" variant="rectangular" height={80} />
            <Skeleton animation="wave" variant="text" width="80%" />
            <Skeleton animation="wave" variant="text" width="60%" />
          </>
        ) : (
          <>
            <Typography
              sx={{ padding: "0.5rem 0 0", fontWeight: 600, fontSize: "14px" }}
            >
              Title
            </Typography>
            <Input
              autoFocus
              margin="dense"
              label=""
              type="text"
              fullWidth
              variant="standard"
              onChange={handleActionChange}
              error={!!actionError}
              helperText={actionError}
            />
            <FormControl fullWidth margin="dense" variant="standard">
              <Typography
                sx={{
                  padding: "0 0 0.5rem",
                  fontWeight: 600,
                  fontSize: "14px",
                }}
              >
                Type
              </Typography>
              <SelectInput
                placeholder="Select text"
                value={newRow.modifiedBy}
                onChange={(e: SelectChangeEvent<unknown>) =>
                  setNewRow({ ...newRow, modifiedBy: e.target.value as string })
                }
                optionValue={options}
                sx={{ height: "1.5rem" }}
              />
            </FormControl>
            {/*  <FormControl fullWidth margin="dense" variant="standard">
              <InputLabel shrink sx={{ fontWeight: "600", fontSize:'4rem' }}>
                Last Modified
              </InputLabel>
            </FormControl>
            <TextField
              margin="dense"
              type="date"
              value={newRow.lastModified}
              fullWidth
              variant="standard"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setNewRow({ ...newRow, lastModified: e.target.value })
              }
              sx={{
                "& .MuiInputLabel-root": {
                  fontWeight: "700",
                },
                "& .MuiInputLabel-shrink": {
                  fontWeight: "700",
                },
              }}
            /> */}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid size={12} sx={{width:'200px', marginBottom:'1rem'}} display={'flex'} justifyContent={'space-between'}>
            <Buttons type="secondary" text="Cancel" onClick={onClose} />
            <Buttons type="primary" text="Save" onClick={onSave} sx={{margin:'0 1rem 0 0.5rem'}} />
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
