import React, { ChangeEvent, useState, useRef, useEffect } from "react";
import { Typography, Box, Link, Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./style.css";
import Buttons from "../../common/button";
import { FormFieldProps } from "./interface";
import { Input } from "../../common/input";

type BotSettingsFormProps = {};

const StarterQuestion = (props: any) => {
  const { questions, setQuestions } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const addButtonRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 400);
    return () => clearTimeout(timer);
  }, []);

  const handleQuestionChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newQuestions = [...questions];
    newQuestions[index] = event.target.value;
    setQuestions(newQuestions);
  };

  const addNewStarterQuestion = () => {
    setQuestions([...questions, ""]);
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
    if (addButtonRef.current) {
      addButtonRef.current.focus();
    }
  };
  const handleDelete = (index: any) => {
    const filteredArray = questions.filter((_: any, i: any) => i !== index);
    setQuestions(filteredArray);
  };

  return (
    <div style={{ width: "95%" }}>
      <Box p={2} className="starterForm" ref={containerRef}>
        <Grid container>
          <Grid size={9}>
            {loading ? (
              <Skeleton
                animation="wave"
                variant="text"
                width="80%"
                height={30}
              />
            ) : (
              <Typography pr={2}>
                These will be shown upfront to your user as nudges. You can
                style the answers using markdown.
              </Typography>
            )}
          </Grid>
          <Grid size={2}>
            {loading ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="100px"
                height={30}
              />
            ) : (
              <Buttons type="secondary" text="Learn more" />
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid size={12}>
            {loading ? (
              <Skeleton
                animation="wave"
                variant="text"
                width="60%"
                height={20}
              />
            ) : (
              <Typography variant="caption">
                Note: Now you can use @ symbol to add variables.
                <Link
                  href="#"
                  className="primaryLink"
                  sx={{ marginLeft: "0.5rem" }}
                >
                  Learn more
                </Link>
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <form noValidate autoComplete="off">
            {questions.length > 0 && (
              <Grid container marginBottom={"0.5rem"}>
                {loading ? (
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="40%"
                    height={20}
                  />
                ) : (
                  <Typography variant="body1">Add starter question</Typography>
                )}
                {questions.map((_: any, index: any) => (
                  <div key={index} className="formFieldsContainer">
                    <Grid size={12} sx={{ marginBottom: "0.5rem" }}>
                      {loading ? (
                        <>
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width="20%"
                            height={20}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width="100%"
                            height={40}
                          />
                        </>
                      ) : (
                        <>
                          <Input
                            label="Question"
                            name={`question${index}`}
                            value={questions[index]}
                            onChange={(event: ChangeEvent<HTMLInputElement>) =>
                              handleQuestionChange(index, event)
                            }
                          />
                        </>
                      )}
                    </Grid>
                    {/* <Grid size={12}>
                      {loading ? (
                        <>
                          <Skeleton animation="wave" variant="text" width="20%" height={20} />
                          <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
                        </>
                      ) : (
                        <>
                          <label>Answer *</label>
                          <Input
                            name={`answer${index}`}
                            value={answers[index]}
                            onChange={(event: ChangeEvent<HTMLInputElement>) => handleAnswerChange(index, event)}
                          />
                        </>
                      )}
                    </Grid> */}
                    <Grid size={12} className="btnFlexEnd">
                      {loading ? (
                        <>
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width="100px"
                            height={30}
                          />
                          {/* <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width="100px"
                            height={30}
                          /> */}
                        </>
                      ) : (
                        <>
                          <Buttons
                            type="secondary"
                            text="Delete"
                            sx={{
                              margin: "0.5rem 0",
                              width: "100px",
                              display: "flex",
                              justifySelf: "right",
                            }}
                            onClick={() => handleDelete(index)}
                          />
                          {/* <Buttons
                            type="primary"
                            text="Save"
                            sx={{ margin: "0.5rem 0", width: "100px" }}
                            onClick={() => handleSave(index)}
                          /> */}
                        </>
                      )}
                    </Grid>
                  </div>
                ))}
              </Grid>
            )}
            <Grid size={5}>
              {loading ? (
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width="200px"
                  height={40}
                />
              ) : (
                <Buttons
                  sx={{ marginTop: "1rem", width: "200px" }}
                  type="secondary"
                  start="addIcon"
                  text="Add new starter question"
                  ref={addButtonRef}
                  onClick={addNewStarterQuestion}
                  className="stickyButton"
                />
              )}
            </Grid>
          </form>
        </Grid>
      </Box>
    </div>
  );
};

export default StarterQuestion;
