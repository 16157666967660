import React, { ChangeEvent, useState, useEffect } from "react";
import {
  FormControl,
  Typography,
  FormControlLabel,
  Switch,
  Box,
  Skeleton,
} from "@mui/material";
import { Input } from "../../common/input";
import Grid from "@mui/material/Grid2";
import "./style.css";
import { SelectInput } from "../../common/selectInput";

const options = [
  {
    text: "50 Messages per user per day",
    value: "50 Messages per user per day",
  },
];

const UsageSecurity: React.FC = () => {
  const [accessUser, setAccessUser] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    // Simulate a loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 400);
    return () => clearTimeout(timer);
  }, []);

  const handleAccessUsers = (event: ChangeEvent<HTMLInputElement>) =>
    setAccessUser(event.target.checked);

  return (
    <Box className="usageSecurity">
      <Grid container>
        <Grid size={12}>
          {loading ? (
            <Skeleton animation="wave" variant="text" width="60%" height={30} />
          ) : (
            <Typography>Adjust the usage limit for your users</Typography>
          )}
        </Grid>
        <Grid size={12}>
          {loading ? (
            <>
              <Skeleton animation="wave" variant="text" width="40%" height={20} />
              <Skeleton animation="wave" variant="text" width="60%" height={20} />
              <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
            </>
          ) : (
            <FormControl fullWidth margin="normal">
              <FormControlLabel
                className="settingFormLable"
                control={
                  <SelectInput
                    placeholder={"Select AI Model"}
                    fullWidth
                    value={"50 Messages per user per day"}
                    optionValue={options}
                  />
                }
                label={
                  <Grid size={8}>
                    <Typography>Usage limit per user</Typography>
                    <Typography variant="caption">
                      This limit applies to both the widget and messaging apps
                      like WhatsApp, Messenger, and Telegram.
                    </Typography>
                  </Grid>
                }
              />
            </FormControl>
          )}
        </Grid>
      </Grid>

      <Grid size={12}>
        {loading ? (
          <>
            <Skeleton animation="wave" variant="text" width="40%" height={20} />
            <Skeleton animation="wave" variant="text" width="60%" height={20} />
            <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
          </>
        ) : (
          <>
            <Typography gutterBottom>User limit warning</Typography>
            <Typography variant="caption">
              Set warning message to show when user limit exceeds
            </Typography>
            <Input />
          </>
        )}
      </Grid>

      <Grid size={12} sx={{ marginTop: "1rem" }}>
        {loading ? (
          <>
            <Skeleton animation="wave" variant="text" width="40%" height={20} />
            <Skeleton animation="wave" variant="text" width="60%" height={20} />
            <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
          </>
        ) : (
          <>
            <Typography>Configure Bot rate limit</Typography>
            <Typography variant="caption">
              Set API rate limit per minute. The maximum rate limit is 300 per
              minute.
            </Typography>
            <Input />
          </>
        )}
      </Grid>

      <Grid size={12}>
        {loading ? (
          <>
            <Skeleton animation="wave" variant="text" width="40%" height={20} />
            <Skeleton animation="wave" variant="text" width="60%" height={20} />
            <Skeleton animation="wave" variant="rectangular" width="40%" height={20} />
          </>
        ) : (
          <FormControl fullWidth margin="normal">
            <FormControlLabel
              className="settingFormLable"
              control={
                <Switch checked={accessUser} onChange={handleAccessUsers} />
              }
              label={
                <>
                  <Typography>Access for users with Masked IPs</Typography>
                  <Typography variant="caption">
                    Block users with masked IPs from accessing the chatbot
                  </Typography>
                </>
              }
            />
          </FormControl>
        )}
      </Grid>
    </Box>
  );
};

export default UsageSecurity;