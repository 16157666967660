import { Outlet, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid2";
import { Box, Skeleton } from "@mui/material";
import { MessageAlert } from "../common/snackBar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setAlert } from "../../redux/features/alert/alertSlice";
import { UiHeader } from "./uiHeader";
import Sidebar from "./sidebar";
import { Page } from "../../routes/config";

export const Header = () => {
  const { open, message } = useSelector((state: any) => state.alert);
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setAlert({ open: false, alertType: "", message: message }));
    }, 5000);
  }, [open]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 400);
  }, []);

  return (
    <div style={{ display: "flex", height: "100%" }}>
      {location?.pathname !== Page.CHAT && (
        <div style={{ width: "240px", background: "#7A72E14D" }}>
          {loading ? <Skeleton animation="wave" variant="rectangular" width={240} height="100vh" /> : <Sidebar />}
        </div>
      )}
      <div
        style={{
          width: location?.pathname !== Page.CHAT ? "calc(100% - 242px)" : "100%",
        }}
      >
        {location?.pathname !== Page.CHAT && (loading ? <Skeleton animation="wave" variant="rectangular" height={64} /> : <UiHeader />)}

        <MessageAlert />
        <div
          style={{
            padding: "32px",
            overflow: "auto",
            height: "100vh",
            paddingBottom: location?.pathname === Page.CHAT ? "76px" : "92px",
          }}
        >
          {loading ? <Skeleton animation="wave" variant="rectangular" width="100%" height="100%" /> : <Outlet />}
        </div>
      </div>
    </div>
  );
};