import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Switch,
  Skeleton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import Buttons from "../../../common/button";
import Grid from "@mui/material/Grid2";
import { Table } from "../../../common/table";
import { SEARCHBAR } from "../../../common/search";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { Column, RowData } from "../interface";
import { ViewDetailsImage } from "../../../../assets/icons/iconcomponents";
import { AddRow } from "./AddRow";
import { EditRow } from "./EditRow";
import { ViewDetailsRow } from "./ViewDetailsRow";
import { useNavigate } from "react-router-dom";

let uniqueId = 1;
const generateId = (): number => {
  return uniqueId++;
};
const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const rowsData: RowData[] = [
  {
    id: generateId(),
    action: "Action 1",
    active: true,
    modifiedBy: "Custom",
    lastModified: formatDate(new Date()),
  },
];
function debounce<T extends (...args: any[]) => void>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout>;
  return function (...args: Parameters<T>) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}
export const Actions = () => {
  const [rowData, setRowData] = useState<RowData[]>(rowsData);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0,
  });
  const newRowObj = {
    id: generateId(),
    action: "",
    active: false, // <Switch checked={false} onChange={() => {}} />
    modifiedBy: "",
    lastModified: "",
  };
  const [search, setSearch] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedRow, setSelectedRow] = useState<null | number>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [newRow, setNewRow] = useState<RowData>(newRowObj);
  const [editRow, setEditRow] = useState<RowData | null>(null);
  const [actionError, setActionError] = useState<string | null>(null);

  const handleSetStatus = useCallback(
    (event: ChangeEvent<HTMLInputElement>, rowId: number) => {
      setRowData((prevRowData) =>
        prevRowData.map((row) =>
          row.id === rowId ? { ...row, active: event.target.checked } : row
        )
      );
    },
    []
  );

  const handleSearch = useCallback(
    debounce((e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
      setPagination((prev) => ({ ...prev, page: 0 }));
    }, 300),
    []
  );

  const handleMenuOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>, rowId: number) => {
      setAnchorEl(event.currentTarget);
      setSelectedRow(rowId);
    },
    []
  );

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
    setSelectedRow(null);
  }, []);

  const handleAdd = useCallback(() => {
    setOpenDialog(true);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
    handleMenuClose();
  }, [handleMenuClose]);
  const navigate = useNavigate();

  const handleAddStep2 = useCallback(() => {
    navigate("/assistant/actions/create");
    handleMenuClose();
  }, [navigate, handleMenuClose]);

  const handleDeleteRow = useCallback(
    (rowId: number) => {
      setRowData((prevRowData) => {
        const updatedRows = prevRowData.filter((row) => row.id !== rowId);
        setRowCount(updatedRows.length);
        return updatedRows;
      });
      handleMenuClose();
    },
    [handleMenuClose]
  );

  const handleDialogSave = useCallback(() => {
    if (!newRow.action || newRow.action.trim() === "") {
      setActionError("Action name cannot be empty");
    } else if (newRow.action.length < 3) {
      setActionError("Action name must be at least 3 characters");
    } else {
      setActionError(null);
      setRowData((prevRowData) => [
        ...prevRowData,
        {
          ...newRow,
          id: generateId(),
          active: (
            <Switch
              checked={newRow.active}
              onChange={(event) => handleSetStatus(event, generateId())}
            />
          ),
        },
      ]);
      setRowCount((prevCount) => prevCount + 1);
      setOpenDialog(false);
    }
  }, [newRow, handleSetStatus]);

  const handleDialogClose = useCallback(() => {
    setOpenDialog(false);
  }, []);

  const handleEditSave = useCallback(() => {
    if (editRow) {
      setRowData((prevRowData) =>
        prevRowData.map((row) => (row.id === editRow.id ? editRow : row))
      );
      setOpenEditDialog(false);
    }
  }, [editRow]);

  const handleEdit = useCallback(() => {
    const row = rowData.find((row) => row.id === selectedRow);
    if (row) {
      setEditRow(row);
      setOpenEditDialog(true);
    }
    handleMenuClose();
  }, [rowData, selectedRow, handleMenuClose]);

  const handleViewDialogClose = useCallback(() => {
    setOpenViewDialog(false);
  }, []);

  const handleEditDialogClose = useCallback(() => {
    setOpenEditDialog(false);
  }, []);

  const handleViewDetails = useCallback(() => {
    setLoading(true);
    const row = rowData.find((row) => row.id === selectedRow);
    if (row) {
      setEditRow(row);
      setOpenViewDialog(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
    handleMenuClose();
  }, [rowData, selectedRow, handleMenuClose]);
  const columns: Column[] = useMemo(
    () => [
      {
        field: "action",
        headerName: "Action",
        minWidth: 600,
        headerAlign: "left",
        sortable: false,
        cellClassName: "cell-padding",
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return loading ? (
            <Skeleton animation="wave" variant="text" width="80%" />
          ) : (
            <p className="slice flex">{params.row.action}</p>
          );
        },
      },
      {
        field: "active",
        headerName: "Status",
        minWidth: 80,
        headerAlign: "left",
        sortable: false,
        cellClassName: "cell-padding",
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return loading ? (
            <Skeleton animation="wave" variant="rectangular" width={40} height={20} />
          ) : (
            <Switch
              checked={params.row.active}
              onChange={(event) => handleSetStatus(event, params.row.id)}
            />
          );
        },
      },
      {
        field: "modifiedBy",
        headerName: "Modified By",
        minWidth: 80,
        headerAlign: "left",
        sortable: false,
        cellClassName: "cell-padding",
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return loading ? (
            <Skeleton animation="wave" variant="text" width="60%" />
          ) : (
            <p className="slice flex">{params.row.modifiedBy}</p>
          );
        },
      },
      {
        field: "actions",
        headerName: "Last modified",
        minWidth: 200,
        headerAlign: "left",
        sortable: false,
        cellClassName: "cell-padding",
        flex: 1,
        renderCell: (params: GridRenderCellParams) => {
          return loading ? (
            <Skeleton animation="wave" variant="text" width="40%" />
          ) : (
            <>
              <span>{params.row.lastModified}</span>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(event) => handleMenuOpen(event, params.row.id)}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl) && selectedRow === params.row.id}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleViewDetails}>
                  <ViewDetailsImage /> View Details
                </MenuItem>
                <MenuItem onClick={handleAddStep2}>
                  <EditOutlinedIcon sx={{color:'#8B8D97'}} /> Edit
                </MenuItem>
                <MenuItem onClick={() => handleDeleteRow(params.row.id)}>
                  <DeleteOutlinedIcon sx={{color:'#8B8D97'}} /> Delete
                </MenuItem>
              </Menu>
            </>
          );
        },
      },
    ],
    [
      loading,
      handleSetStatus,
      handleMenuOpen,
      handleMenuClose,
      handleViewDetails,
      handleEdit,
      handleDeleteRow,
      anchorEl,
      selectedRow,
    ]
  );
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  return (
    <>
      <Grid
        container
        sx={{ margin: "1rem 0 2rem" }}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Typography variant="body1" className="heading">
          Let your AI Agent take action on behalf of customers. Example actions
          may include: getting customer info, order lookup, submitting tickets,
          etc.
        </Typography>
      </Grid>
      <Grid
        container
        spacing={8}
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Grid size={4}>
          <SEARCHBAR
            onChange={handleSearch}
            value={search}
            placeholder={"Search by title"}
          />
        </Grid>
        <Grid>
          <Buttons
            type="primary"
            onClick={handleAdd}
            start="addIcon"
            text="Add new"
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid size={12} sx={{ marginTop: "1.25rem" }}>
          <Table
            rows={rowData}
            columns={columns}
            isPagination={true}
            rowCount={rowCount}
            sx={{
              "& .MuiDataGrid-row:nth-of-type(even)": {
                backgroundColor: "transparent", 
              },
            }}
          />
        </Grid>
      </Grid>
      <AddRow
        open={openDialog}
        onClose={handleDialogClose}
        // onSave={handleDialogSave}
        loading={loading}
        onSave={handleAddStep2}
        newRow={newRow}
        setNewRow={setNewRow}
        actionError={actionError}
        setActionError={setActionError}
      />
      <EditRow
        open={openEditDialog}
        onClose={handleEditDialogClose}
        onSave={handleEditSave}
        editRow={editRow}
        setEditRow={setEditRow}
      />
      <ViewDetailsRow
        onClose={handleViewDialogClose}
        row={editRow}
        open={openViewDialog}
        loading={loading}
      />
    </>
  );
};
