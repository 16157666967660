import { CssBaseline, Box } from "@mui/material";
import { logo } from "../../assets/images";
import { MessageAlert } from "../common/snackBar";
import "./style.css";

export const LoginHeader = () => {
  return (
    <>
      <CssBaseline />
      {/* Navbar */}
      <Box
        component="nav"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "12px 74px",
          boxShadow: "0px 1px 2px 0px #0000004D",
        }}
      >
        <span className="logo">Virat</span>
        <MessageAlert></MessageAlert>
        {/* <Box>
    <Link href="#" underline="none" sx={{ margin: "0 1rem", color: "gray" }}>
      Home
    </Link>
    <Link href="#" underline="none" sx={{ margin: "0 1rem", color: "gray" }}>
      Products
    </Link>
    <Link href="#" underline="none" sx={{ margin: "0 1rem", color: "gray" }}>
      About us
    </Link>
    <Link href="#" underline="none" sx={{ margin: "0 1rem", color: "blue", fontWeight: "bold" }}>
      Sign Up
    </Link>
    <Link href="#" underline="none" sx={{ margin: "0 1rem", color: "purple", fontWeight: "bold" }}>
      Sign In
    </Link>
  </Box> */}
      </Box>
    </>
  );
};
