import { Card } from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from "recharts";

import { useGetChatSesionAnalyticQuery } from "../../../../redux/services/analyticsApiSlice";

export const Analytics = () => {
  const { data } = useGetChatSesionAnalyticQuery<any>({});

  const graphdata = data?.chat_sessions;
  return (
    <>
      <Grid container>
        <Grid size={12}></Grid>
      </Grid>
      <Card
        className="dataCard"
        sx={{ background: "#F9F9FF", height: "300px" }}
      >
        <ResponsiveContainer width="100%" height="88%">
          <BarChart
            width={500}
            height={300}
            data={graphdata}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid horizontal={false} vertical={false} />
            <XAxis
              // dataKey="_id"
              padding={{ left: 30, right: 10 }}
              axisLine={false}
              tickLine={false}
            />
            <YAxis
              // tickFormatter={(value) => `${value / 1000}k`}
              axisLine={false}
              tickLine={false}
            />
            <Tooltip cursor={{ fill: "transparent" }} />
            <defs>
              <linearGradient id="barGradient" x1="0" y1="0" x2="0" y2="1">
                <stop offset="20.5%" stopColor="#2200FF" />
                <stop offset="100%" stopColor="#D230FF" />
              </linearGradient>
            </defs>
            <Bar
              dataKey="session_count"
              fill="#0000001A"
              radius={[20, 20, 20, 20]}
              activeBar={{
                fill: "url(#barGradient)", // Gradient fill on hover
                r: 20, // Rounded corners on hover
                stroke: "none", // No border
                strokeWidth: 0, // No border width
              }}
            />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    </>
  );
};
