import {
  Box,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import { loading } from "../../assets/images";
import { useState, useEffect, useRef } from "react";
import {
  useCreateChatMutation,
  useCreateChatSessionMutation,
  useGetChatHistoryMutation,
} from "../../redux/services/chatApiSlice";
import {
  useGetStarterQuestionQuery,
  useGetWelcomeMessageQuery,
  useTrainBotMutation,
  useUploadFileMutation,
} from "../../redux/services/botApiSlice";
import CloseIcon from "@mui/icons-material/Close";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import "./style.css";

export const TestBot = (props: any) => {
  const { chatbotId, widget, isOpen, setIsOpen, setTestBotOpen } = props;
  const formData = new FormData();

  const [messages, setMessages] = useState<any>([]);
  const [inputValue, setInputValue] = useState("");

  const [sessionId, setSessionId] = useState();
  const [count, setCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const [question, setQuestion] = useState("");
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [isFileSending, setIsFileSending] = useState(false);
  const [starterQuestion, setStarterQuestion] = useState([]);
  const [selectedStarterQues, setSelectedStarterQues] = useState();

  const [createSession, createSessionInfo] =
    useCreateChatSessionMutation<any>();
  const [uploadFile, uploadFileInfo] = useUploadFileMutation();
  const [createChat, createChatInfo] = useCreateChatMutation();
  const [getChatHistory, getChatHistoryInfo] = useGetChatHistoryMutation<any>();
  const [trainBot, trainBotInfo] = useTrainBotMutation();
  const { data, isSuccess, isLoading } = useGetWelcomeMessageQuery<any>({
    chatbot_id: chatbotId,
  });
  const getStarterQuestionQuery = useGetStarterQuestionQuery<any>({
    chatbot_id: chatbotId,
  });

  useEffect(() => {
    if (createSessionInfo?.isSuccess) {
      setSessionId(createSessionInfo?.data?.session_id);
      setCount((prev) => prev + 1);
      createChat({
        chatbot_id: chatbotId,
        session_id: createSessionInfo?.data?.session_id,
        question: question,
      });
    }
  }, [createSessionInfo?.isSuccess]);

  useEffect(() => {
    if (createChatInfo?.isSuccess) {
      getChatHistory({
        chatbot_id: chatbotId,
        session_id: sessionId,
      });
    }
  }, [createChatInfo?.isSuccess]);

  useEffect(() => {
    if (getChatHistoryInfo?.isSuccess) {
      setLoader(false);
      setMessages(getChatHistoryInfo?.data?.["chat-history"]);
    }
  }, [getChatHistoryInfo?.isSuccess]);

  useEffect(() => {
    if (uploadFileInfo?.isSuccess) {
      trainBot({
        chatbot_id: chatbotId,
      });
    }
  }, [uploadFileInfo?.isSuccess]);

  useEffect(() => {
    if (uploadFileInfo?.isError) {
      setIsFileSending(false);
    }
  }, [uploadFileInfo?.isError]);

  useEffect(() => {
    if (trainBotInfo?.isSuccess) {
      setMessages((prevMessages: any) => [
        ...prevMessages,
        {
          role: "user",
          message: `Uploaded: ${uploadedFile.name}`,
          files: [{ name: uploadedFile.name }],
        },
      ]);

      setUploadedFile(null);
      setIsFileSending(false);
      setInputValue("");
    }
  }, [trainBotInfo?.isSuccess]);

  useEffect(() => {
    if (trainBotInfo?.isError) {
      setIsFileSending(false);
    }
  }, [trainBotInfo?.isError]);

  const toggleChat = () => {
    setIsOpen(false);
    setTestBotOpen(false);
  };

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
    setQuestion(e.target.value);
  };
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const handleSend = () => {
    if (count === 0) {
      setLoader(true);
      setMessages([...messages, { role: "user", message: question }]);
      setInputValue("");
      createSession({
        chatbot_id: chatbotId,
        question: question,
      });
    } else {
      setStarterQuestion([]);
      if (uploadedFile) {
        setIsFileSending(true);
        formData.append("chatbot_id", chatbotId);
        formData.append("files", uploadedFile);
        uploadFile(formData);
      } else {
        setLoader(true);
        setMessages([...messages, { role: "user", message: question }]);
        setInputValue("");
        createChat({
          chatbot_id: chatbotId,
          session_id: sessionId,
          question: question,
        });
      }
      setTimeout(() => {
        scrollToBottom();
      }, 0);
    }
  };
  const handleFileChange = (event: any) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadedFile(file);
    }
  };
  useEffect(() => {
    if (isOpen && sessionId !== null && sessionId !== undefined) {
      setLoader(true);
      getChatHistory({
        chatbot_id: chatbotId,
        session_id: sessionId,
      });
    }
  }, [isOpen]);
  const readAloud = (text: any) => {
    // Stop any ongoing speech
    if (window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel();
    }

    // Function to sanitize text
    const sanitizeText = (input: any) => {
      return input.replace(/[\{\}\[\]\\_\^\$&%#~*`]/g, " ");
    };

    // Split text into manageable chunks
    const sentences = sanitizeText(text).split(/(?<=[.!?])\s+/);

    // Speak each chunk sequentially
    const speakChunk = (chunk: any, index: any) => {
      if (index < sentences.length) {
        const utterance = new SpeechSynthesisUtterance(chunk);
        utterance.lang = "en-US";
        utterance.rate = 1;
        utterance.pitch = 1;
        utterance.volume = 1;

        // Speak the current chunk and proceed to the next
        utterance.onend = () => {
          if (index + 1 < sentences.length) {
            speakChunk(sentences[index + 1], index + 1);
          } else {
            setIsSpeaking(false); // Reset the icon when speech ends
          }
        };

        utterance.onerror = (event) => {
          console.error("Speech synthesis error:", event.error);
          setIsSpeaking(false);
        };

        window.speechSynthesis.speak(utterance);
      }
    };

    // Start speaking the first chunk
    if (sentences.length > 0) {
      setIsSpeaking(true);
      speakChunk(sentences[0], 0);
    }
  };

  const stopSpeech = () => {
    if (window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel();
    }
    setIsSpeaking(false);
  };
  const copyText = async (text: any) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };
  useEffect(() => {
    if (uploadedFile) {
      setInputValue(uploadedFile.name);
    }
  }, [uploadedFile]);
  const chatContainerRef: any = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const container = chatContainerRef.current;
      if (container) {
        if (container) {
          const isAtBottom =
            container.scrollHeight -
              container.scrollTop -
              container.clientHeight <=
            200;
        }
      }
    };

    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      scrollToBottom();
    }
  }, [isOpen]);
  useEffect(() => {
    if (isSuccess) {
      setMessages([{ role: "bot", message: data?.welcome_message }]);
    }
  }, [isSuccess]);
  useEffect(() => {
    if (getStarterQuestionQuery?.isSuccess) {
      setStarterQuestion(getStarterQuestionQuery?.data?.starter_questions);
    }
  }, [getStarterQuestionQuery?.isSuccess]);
  const handleStarterQuestionClick = (x: any) => {
    setQuestion(x);
    setSelectedStarterQues(x);
  };
  useEffect(() => {
    if (selectedStarterQues) {
      handleSend();
    }
  }, [selectedStarterQues]);

  return (
    <>
      <Box
        position="fixed"
        bottom={16}
        right={16}
        zIndex={1000}
        justifyItems={"right"}
        display={"grid"}
      >
        {isOpen && (
          <Box
            sx={{
              position: "absolute",
              bottom: "62px",
              width: 384,
              height: 530,
              bgcolor: "background.paper",
              boxShadow: 3,
              borderRadius: 3,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 1,
                bgcolor: `${widget?.accent_color}`,
                color: "primary.contrastText",
                paddingLeft: "40px",
              }}
            >
              {/* <div>
              <img src={widget?.icon_url}></img>
            </div> */}
              <div>
                <Typography variant="h6">{widget?.name}</Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  Our bot answers instantly
                </Typography>
              </div>
              <div>
                <IconButton color="inherit" onClick={toggleChat}>
                  <CloseIcon />
                </IconButton>{" "}
              </div>
            </Box>
            <Box
              sx={{
                flex: 1,
                p: 2,
                overflowY: "auto",
                overflowX: "hidden",
              }}
              ref={chatContainerRef}
            >
              {messages.length === 0 ? (
                <Skeleton variant="rectangular" width="100%" height={118} />
              ) : (
                messages.map((msg: any, index: any) => {
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent:
                            msg.role === "user" ? "right" : undefined,
                        }}
                      >
                        {/* {msg.role === "bot" && (
                      <img
                        style={{ width: "24px", height: "24px" }}
                        src={widget?.icon_url}
                      ></img>
                    )} */}
                        <Typography
                          key={index}
                          className="messagePara"
                          sx={{
                            mb: 1,
                            background:
                              msg.role === "user"
                                ? `${widget?.accent_color}`
                                : "#ededed",
                            padding: "5px",
                            borderRadius: "10px",
                            marginLeft: "5px",
                            color: msg.role === "user" ? "white" : "black",
                            fontSize: "14px",
                            fontWeight: "400",
                            fontFamily: "Nunito, sans-serif",
                          }}
                        >
                          <ReactMarkdown>{msg.message}</ReactMarkdown>
                        </Typography>
                      </div>
                      {msg.role === "bot" && (
                        <span
                          style={{
                            width: "fit-content",
                            display: "flex",
                            height: "25px",
                            paddingLeft: "12px",
                          }}
                        >
                          {isSpeaking ? (
                            <StopCircleOutlinedIcon
                              sx={{
                                color: "#0000007d",
                                cursor: "pointer",
                                width: "20px",
                                height: "20px",
                              }}
                              onClick={() => stopSpeech()}
                            />
                          ) : (
                            <VolumeUpOutlinedIcon
                              sx={{
                                color: "#0000007d",
                                cursor: "pointer",
                                width: "20px",
                                height: "20px",
                              }}
                              onClick={() => readAloud(msg.message)}
                            />
                          )}
                          <ContentCopyOutlinedIcon
                            sx={{
                              color: "#0000007d",
                              cursor: "pointer",
                              width: "20px",
                              height: "20px",
                            }}
                            onClick={() => copyText(msg.message)}
                          />
                        </span>
                      )}
                      {/* {msg.files && msg.files.length > 0 && (
                    <div style={{ marginTop: "8px" }}>
                      {msg.files.map((file, fileIndex) => (
                        <a
                          key={fileIndex}
                          href="#"
                          style={{
                            display: "block",
                            color: "#007BFF",
                            textDecoration: "none",
                            fontSize: "14px",
                          }}
                        >
                          {file.name}
                        </a>
                      ))}
                    </div>
                  )} */}
                    </>
                  );
                })
              )}
              {loader && (
                <div
                  style={{
                    maxWidth: "9%",
                    padding: "10px 15px",
                    backgroundColor: "#f1f1f1",
                    marginBottom: "5px",
                    marginLeft: "10px",
                    borderRadius: "8px",
                    marginTop: "10px",
                  }}
                >
                  <div className="typing-animation">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              )}
            </Box>
            <Box sx={{ display: "ruby" }}>
              {starterQuestion?.map((x: any) => {
                return (
                  <Box
                    component={"div"}
                    className="starterQuestDiv"
                    sx={{
                      fontSize: "12px",
                      border: `1px solid ${widget?.accent_color}`,
                      padding: "5px 5px",
                      width: "fit-content",
                      whiteSpace: "nowrap",
                      margin: "5px",
                      cursor: "pointer",
                      color: `${widget?.accent_color}`,
                      "&:hover": {
                        background: `${widget?.accent_color}1a`,
                      },
                    }}
                    onClick={() => handleStarterQuestionClick(x)}
                  >
                    {x}
                  </Box>
                );
              })}
            </Box>

            <Box
              sx={{
                p: 1,
                borderTop: 1,
                borderColor: "divider",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                fullWidth
                value={inputValue}
                onChange={handleInputChange}
                maxRows={5}
                minRows={1}
                multiline={true}
                placeholder="Type a message..."
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiInputBase-root": {
                    paddingRight: "0px",
                    paddingLeft: "0px",
                  },
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    handleSend();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <IconButton type="submit" disabled={isFileSending}>
                          {isFileSending ? (
                            <img
                              src={loading}
                              style={{ width: "20px", height: "20px" }}
                            ></img>
                          ) : (
                            <SendIcon onClick={handleSend} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    </>
                  ),
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton component="label">
                        <AttachFileIcon />
                        <input type="file" hidden onChange={handleFileChange} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <span
              style={{
                width: "max-content",
                alignSelf: "center",
                padding: "5px",
                fontSize: "14px",
                fontWeight: "400",
              }}
            >
              Powered by{" "}
              <span style={{ color: `${widget?.accent_color}` }}>PtGPT</span>
            </span>
          </Box>
        )}
      </Box>
    </>
  );
};
