import { useEffect, useState } from "react";
import { Box, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

const ImageSlider = ({ images }: any) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "85%",
        overflow: "hidden",
      }}
    >
      {/* Image Container */}
      <Box
        sx={{
          display: "flex",
          transform: `translateX(-${currentIndex * 100}%)`,
          transition: "transform 0.5s ease-in-out",
        }}
      >
        {images.map((image: any, index: any) => (
          //   <Box
          //     key={index}
          //     component="img"
          //     src={image}
          //     alt={`Slide ${index}`}
          //     sx={{
          //       minWidth: "100%",
          //       height: "100%",
          //       objectFit: "cover",
          //     }}
          //   />
          <Box
            component="img"
            src={image}
            alt="Robot"
            sx={{
              minWidth: "100%",
              height: "100%",
              objectFit: "cover",
              marginTop: "-96px",
            }}
          />
        ))}
      </Box>

      {/* Previous Button */}
      <IconButton
        onClick={handlePrevious}
        sx={{
          position: "absolute",
          top: "50%",
          left: "10px",
          transform: "translateY(-50%)",
          zIndex: 1,
          bgcolor: "rgba(255, 255, 255, 0.6)",
        }}
      >
        <ChevronLeft />
      </IconButton>

      {/* Next Button */}
      <IconButton
        onClick={handleNext}
        sx={{
          position: "absolute",
          top: "50%",
          right: "10px",
          transform: "translateY(-50%)",
          zIndex: 1,
          bgcolor: "rgba(255, 255, 255, 0.6)",
        }}
      >
        <ChevronRight />
      </IconButton>
    </Box>
  );
};

export default ImageSlider;
