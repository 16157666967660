import { ChangeEvent, useState, useEffect } from "react";
import {
  FormControl,
  Typography,
  FormControlLabel,
  Switch,
  Box,
  Link,
  Badge,
  IconButton,
  Skeleton,
} from "@mui/material";
import { Input } from "../../common/input";
import Grid from "@mui/material/Grid2";
import "./style.css";
import Buttons from "../../common/button";
import { SelectInput } from "../../common/selectInput";

const options = [
  {
    text: "Medium",
    value: "Medium",
  },
  {
    text: "Short",
    value: "Short",
  },
  {
    text: "Long",
    value: "Long",
  },
];

const Messaging = (props: any) => {
  const { welcomeMessage, setWelcomeMessage, loading } = props;

  const [conversationalMode, setConversationalMode] = useState<boolean>(false);
  const [postChat, setPostChat] = useState<boolean>(false);
  const [showSources, setShowSources] = useState<boolean>(true);

  const handleConversationalModeChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    setConversationalMode(event.target.checked);
  };
  const handleShowSourcesChange = (event: ChangeEvent<HTMLInputElement>) => {
    setShowSources(event.target.checked);
  };
  const handlePostChangeEvent = (event: ChangeEvent<HTMLInputElement>) => {
    setPostChat(event.target.checked);
  };
  const handleWelcomeMessage = (event: any) => {
    setWelcomeMessage(event.target.value);
  };

  return (
    <Box p={2}>
      <Grid container>
        <Grid size={12}>
          {loading ? (
            <Skeleton animation="wave" variant="text" width="60%" height={30} />
          ) : (
            <FormControlLabel
              className="settingFormLable"
              control={
                <Buttons
                  type="secondary"
                  text="gpt-4o-mini"
                  sx={{ width: "6.5rem" }}
                />
              }
              label={
                <>
                  <Typography>AI Model</Typography>
                  <Typography variant="caption">
                    Select a model to power your bot
                  </Typography>
                </>
              }
            />
          )}
        </Grid>
        <Grid size={12}>
          {loading ? (
            <>
              <Skeleton
                animation="wave"
                variant="text"
                width="40%"
                height={20}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width="60%"
                height={20}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={40}
                height={20}
              />
            </>
          ) : (
            <FormControl fullWidth margin="normal">
              <FormControlLabel
                className="settingFormLable"
                control={
                  <Switch
                    checked={conversationalMode}
                    onChange={handleConversationalModeChange}
                  />
                }
                label={
                  <>
                    <Typography variant="body1">
                      Conversational Mode
                      <IconButton
                        aria-label="comingSoon"
                        sx={{ width: "100px", marginLeft: "-45px" }}
                      >
                        <Badge
                          badgeContent={"Coming Soon"}
                          color="primary"
                          sx={{ width: "100px" }}
                        ></Badge>
                      </IconButton>
                    </Typography>
                    <Typography variant="caption" pr={5}>
                      Segment bot's responses into shorter, more readable
                      messages. The entire response still counts as one message.
                    </Typography>
                  </>
                }
              />
            </FormControl>
          )}
        </Grid>
        <Grid size={12}>
          {loading ? (
            <>
              <Skeleton
                animation="wave"
                variant="text"
                width="40%"
                height={20}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width="60%"
                height={20}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="100%"
                height={40}
              />
            </>
          ) : (
            <FormControl fullWidth margin="normal">
              <FormControlLabel
                className="settingFormLable"
                control={
                  <SelectInput
                    placeholder={"Select AI Model"}
                    fullWidth
                    value={"Medium"}
                    optionValue={options}
                  />
                }
                label={
                  <>
                    <Typography>
                      Response Length{" "}
                      <IconButton
                        aria-label="comingSoon"
                        sx={{ width: "100px", marginLeft: "-45px" }}
                      >
                        <Badge
                          badgeContent={"Coming Soon"}
                          color="primary"
                          sx={{ width: "100px" }}
                        ></Badge>
                      </IconButton>
                    </Typography>
                    <Typography variant="caption">
                      Select the response length(s) of your bot
                    </Typography>
                  </>
                }
              />
            </FormControl>
          )}
        </Grid>
        {/* <Grid size={12}>
          {loading ? (
            <>
              <Skeleton animation="wave" variant="text" width="40%" height={20} />
              <Skeleton animation="wave" variant="text" width="60%" height={20} />
              <Skeleton animation="wave" variant="rectangular" width="40%" height={20} />
            </>
          ) : (
            <FormControl fullWidth margin="normal">
              <FormControlLabel
                className="settingFormLable"
                control={
                  <Switch
                    checked={suggestFollowUpQuestions}
                    onChange={handleSuggestFollowUpQuestionsChange}
                  />
                }
                label={
                  <>
                    <Typography>Suggest Follow Up Questions</Typography>
                    <Typography variant="caption">
                      If enabled, we will suggest 2 new follow up questions from
                      your knowledge base
                    </Typography>
                  </>
                }
              />
            </FormControl>
          )}
        </Grid>
        <Grid size={12}>
          {loading ? (
            <>
              <Skeleton animation="wave" variant="text" width="40%" height={20} />
              <Skeleton animation="wave" variant="text" width="60%" height={20} />
              <Skeleton animation="wave" variant="rectangular" width="40%" height={20} />
            </>
          ) : (
            <FormControl fullWidth margin="normal">
              <FormControlLabel
                className="settingFormLable"
                control={
                  <Switch
                    checked={allowImageUsage}
                    onChange={handleAllowImageUsageChange}
                  />
                }
                label={
                  <>
                    <Typography>Allow Image Usage</Typography>
                    <Typography variant="caption">
                      Allows the bot to use the scraped images for training and
                      response generation
                    </Typography>
                  </>
                }
              />
            </FormControl>
          )}
        </Grid> */}
        <Grid size={12}>
          {loading ? (
            <>
              <Skeleton
                animation="wave"
                variant="text"
                width="40%"
                height={20}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width="60%"
                height={20}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="40%"
                height={20}
              />
            </>
          ) : (
            <FormControl fullWidth margin="normal">
              <FormControlLabel
                className="settingFormLable"
                control={
                  <Switch
                    checked={showSources}
                    onChange={handleShowSourcesChange}
                  />
                }
                label={
                  <>
                    <Typography>
                      Show Sources with the Response{" "}
                      <IconButton
                        aria-label="comingSoon"
                        sx={{ width: "100px", marginLeft: "-45px" }}
                      >
                        <Badge
                          badgeContent={"Coming Soon"}
                          color="primary"
                          sx={{ width: "100px" }}
                        ></Badge>
                      </IconButton>
                    </Typography>
                    <Typography variant="caption">
                      Hide/Show sources along with responses
                    </Typography>
                  </>
                }
              />
            </FormControl>
          )}
        </Grid>
        <Grid size={12}>
          {loading ? (
            <>
              <Skeleton
                animation="wave"
                variant="text"
                width="40%"
                height={20}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width="60%"
                height={20}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="40%"
                height={20}
              />
            </>
          ) : (
            <FormControl fullWidth margin="normal">
              <FormControlLabel
                className="settingFormLable"
                control={
                  <Switch checked={postChat} onChange={handlePostChangeEvent} />
                }
                label={
                  <>
                    <Typography>Post chat feedback</Typography>
                    <Typography variant="caption">
                      Hide/Show post chat feedback
                    </Typography>
                  </>
                }
              />
            </FormControl>
          )}
        </Grid>
        {/* <Grid size={12}>
          {loading ? (
            <>
              <Skeleton variant="text" width="40%" height={20} />
              <Skeleton variant="text" width="60%" height={20} />
              <Skeleton variant="rectangular" width="40%" height={20} />
            </>
          ) : (
            <FormControl fullWidth margin="normal">
              <FormControlLabel
                className="settingFormLable"
                control={
                  <Switch
                    checked={multilingualMode}
                    onChange={handleConversationalMultilingual}
                  />
                }
                label={
                  <>
                    <Typography>Multilingual Support</Typography>
                    <Typography variant="caption">
                      If disabled, the bot will stick to the selected language
                    </Typography>
                  </>
                }
              />
            </FormControl>
          )}
        </Grid>
        <Grid size={12}>
          {loading ? (
            <>
              <Skeleton animation="wave" variant="text" width="40%" height={20} />
              <Skeleton animation="wave" variant="text" width="60%" height={20} />
              <Skeleton animation="wave" variant="rectangular" width="40%" height={20} />
            </>
          ) : (
            <FormControl fullWidth margin="normal">
              <FormControlLabel
                className="settingFormLable"
                control={
                  <Switch
                    checked={welcomeMessage}
                    onChange={handleWelcomeMessageChange}
                  />
                }
                label={
                  <>
                    <Typography>Show floating welcome message</Typography>
                    <Typography variant="caption">
                      Toggle visibility of the Welcome Message
                    </Typography>
                  </>
                }
              />
            </FormControl>
          )}
        </Grid> */}
        <Grid size={12}>
          {loading ? (
            <>
              <Skeleton
                animation="wave"
                variant="text"
                width="40%"
                height={20}
              />
              <Skeleton
                animation="wave"
                variant="text"
                width="60%"
                height={20}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                width="40%"
                height={20}
              />
            </>
          ) : (
            <FormControl fullWidth margin="normal">
              <Typography>Welcome Message</Typography>
              <Typography variant="caption">
                Variables can be added using the "@" symbol
                <Link
                  href="#"
                  variant="body2"
                  className="primaryLink"
                  sx={{ ml: "0.5rem" }}
                >
                  Learn more
                </Link>
              </Typography>
              <Input value={welcomeMessage} onChange={handleWelcomeMessage} />
            </FormControl>
          )}
        </Grid>
        {/* <Grid size={12}>
          {loading ? (
            <>
              <Skeleton animation="wave" variant="text" width="40%" height={20} />
              <Skeleton animation="wave" variant="text" width="60%" height={20} />
              <Skeleton animation="wave" variant="rectangular" width="40%" height={20} />
            </>
          ) : (
            <FormControl fullWidth margin="normal">
              <Typography>Message shown when no Source is added</Typography>
              <Input />
            </FormControl>
          )}
        </Grid>
        <Grid size={12}>
          {loading ? (
            <>
              <Skeleton animation="wave" variant="text" width="100%" height={20} />
            </>
          ) : (
            <FormControl fullWidth margin="normal">
              <Typography>
                Message shown when there is a Server Error
              </Typography>
              <Input />
            </FormControl>
          )}
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default Messaging;
