export enum Page {
  LOGIN = "/",
  OTP = "/verifyotp",
  BOTS = "/bot",
  CREATEBOT = "/bot/create",
  DASHBOARD = "/",
  AGENTS = "/agents",
  KNOWLEDGEBASES = "/knowledgebases",
  CHAT = "/bot/chat",
  ASSISTANT = "/assistant/create",
  SETTINGS = "/assistant/create/settings",
  SOURCES = "/assistant/create/sources",
  GUIDELINES = "/assistant/guidelines",
  CREATE_GUIDELINE = "/assistant/guidelines/create",
  CONNECT = "/assistant/create/connect",
  INTEGRATION = "/assistant/create/integration",
  ACTIONS = "/assistant/actions",
  CREATE_ACTION = "/assistant/actions/create",
  INBOX = "/assistant/create/inbox",
  ANALYTICS = "/assistant/create/analytics",
}
