import React from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, Skeleton } from '@mui/material';
import { RowData } from '../interface';
import Grid from '@mui/material/Grid2';
import { Input } from '../../../common/input';
import Buttons from '../../../common/button';
import { Table } from '../../../common/table';

interface ViewDetailsProps {
  open: boolean;
  onClose: () => void;
  row: RowData | null;
  loading: boolean;
}
interface rows {
  id?:number;
  name:string;
  type:string;
}
const rowsData:rows[]= [
  {
    id:1,
    name:"Name",
     type:"Type",
  }

]
interface cols {
  field: string;
  headerName: string;
  minWidth: number;
  headerAlign: "left" | "center" | "right";
  sortable: boolean;
  cellClassName: string;
  flex: number;
}
const columns: cols[] = [
    {
      field: "action",
      headerName: "Name",
      minWidth: 100,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Type",
      minWidth: 100,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
    },

  ];

export const ViewDetailsRow: React.FC<ViewDetailsProps> = ({ open, onClose, row, loading }) => (
  <Dialog open={open} onClose={onClose}
    sx={{
      "& .MuiDialog-paper": {
        width: "100%",
        maxWidth: "500px",
        borderRadius: "1rem",
      },
    }}
  >
    <DialogTitle>View Details</DialogTitle>
    <DialogContent>
      {loading ? (
        <>
          <Skeleton animation="wave" variant="text" width="80%" />
          <Skeleton animation="wave" variant="text" width="60%" />
          <Skeleton animation="wave" variant="rectangular" height={40} />
          <Skeleton animation="wave" variant="text" width="80%" />
          <Skeleton animation="wave" variant="rectangular" height={80} />
          <Skeleton animation="wave" variant="text" width="80%" />
          <Skeleton animation="wave" variant="text" width="60%" />
        </>
      ) : row ? (
        <>
          <Grid container sx={{ position: "relative", minHeight: '550px', height: "100%" }}>
            <Grid size={12}>
              <Grid container>
                <Grid size={12}>
                  <Typography className="heading">Title</Typography>
                </Grid>
                <Grid size={12}>
                  <Typography variant="caption">
                    For reference only and doesn't affect your bot's behaviour
                  </Typography>
                </Grid>
                <Grid size={12}>
                  <Input />
                </Grid>
              </Grid>
              <Grid container>
                <Typography className="heading" sx={{ paddingTop: "1.25rem" }}>
                  Type
                </Typography>
              </Grid>
              <Grid size={12}>
                <Input multiline={true} minRows={2} />
              </Grid>
              <Grid size={12}>
                <Typography className="heading" sx={{ paddingTop: "1.25rem" }}>
                  Description
                </Typography>
                <Typography variant="body2"> --- ----</Typography>
              </Grid>
              <Grid size={12}>
                <Typography className="heading" sx={{ paddingTop: "1.25rem" }}>
                  List Input
                </Typography>
                <Grid container>
        <Grid size={12} sx={{ marginTop: "1.25rem" }}>
          <Table
            rows={rowsData}
            columns={columns}
            isPagination={true}
            rowCount={0}
            sx={{
              "& .MuiDataGrid-row:nth-of-type(even)": {
                backgroundColor: "transparent", 
              },
            }}
          />
        </Grid>
      </Grid>
              </Grid>

            </Grid>
            <Grid
              size={12}
              sx={{
                position: "absolute",
                bottom: "0.125rem",
                right: "2rem",
                left: "0",
                paddingTop: "0.5rem",
                borderTop: "1px solid var(--bot-border-color)",
              }}
            >
              <Grid display={"flex"} justifyContent={"flex-end"}>
                <Grid size={4} display={"flex"}>
                  <Buttons
                    text="Edit"
                    type="secondary"
                    sx={{ marginRight: "0.5rem" }}
                    onClick={onClose}
                  />
                  <Buttons text="Save" type="primary" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography variant="body1">No details available.</Typography>
      )}
    </DialogContent>
  </Dialog>
);