import {
  Drawer,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Skeleton
} from "@mui/material";
import "./style.css";
import Grid from "@mui/material/Grid2";
import { attachIcon, clear, deleteIcon, send } from "../../../assets/icons";
import { Controller, useForm } from "react-hook-form";
import {
  useCreateChatMutation,
  useCreateChatSessionMutation,
  useDeleteChatSessionMutation,
  useGetChatHistoryMutation,
  useGetChatSessionMutation,
} from "../../../redux/services/chatApiSlice";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Loader } from "../../common/loader";
import {
  useTrainBotMutation,
  useUploadFileMutation,
} from "../../../redux/services/botApiSlice";
import { loading } from "../../../assets/images";
import ReactMarkdown from "react-markdown";
import { filterMessages, groupSessionsByMonth } from "../../../utils";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";
import { SEARCHBAR } from "../../common/search";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";


export const ChatInterface = () => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const stateParam = queryParams.get("state");
  const state = stateParam ? JSON.parse(decodeURIComponent(stateParam)) : {};
  const chatbotId = state?.chatBotId;
  const chatbotName = state?.chatBotName;
  const formData = new FormData();
  const dispatch = useDispatch();
  const [message, setMessage] = useState<any>([]);
  const [showInitialLoader, setShowInitialLoader] = useState(true);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [isFileSending, setIsFileSending] = useState(false);
  const [cardHeight, setCardHeight] = useState(0);
  const [innerCardHeight, setInnerCardHeight] = useState(0);
  const [sessionList, setSessionList] = useState<any>([]);
  const [sessionId, setSessionId] = useState();
  const [question, setQuestion] = useState();
  const [newDialog, setNewDialog] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(true);
  const [isScrolledUp, setIsScrolledUp] = useState(false);

  const [createChat, createChatInfo] = useCreateChatMutation();
  const [getChatHistory, getChatHistoryInfo] = useGetChatHistoryMutation<any>();
  const [uploadFile, uploadFileInfo] = useUploadFileMutation();
  const [trainBot, trainBotInfo] = useTrainBotMutation();
  const [getChatSession, getChatSessionInfo] = useGetChatSessionMutation<any>();
  const [createChatSession, createChatSessionInfo] =
    useCreateChatSessionMutation<any>();
  const [deleteSession, deleteSessionInfo] = useDeleteChatSessionMutation();
  const [chatLoading, setChatLoading] = useState<boolean>(true);
 useEffect(() => {
    const timer = setTimeout(() => {
      setChatLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  const onSubmit = (data: any) => {
    setQuestion(data?.question);
    if (uploadedFile) {
      setIsFileSending(true);
      formData.append("chatbot_id", chatbotId);
      formData.append("files", uploadedFile);
      uploadFile(formData);
    } else {
      // Add the new user message to the chat history
      if (sessionList?.length === 0 || newDialog) {
        const newMessage = {
          role: "user",
          message: data?.question,
          files: null,
        };

        // Update the local message state
        setMessage((prevMessages: any) => [...prevMessages, newMessage]);

        // Clear the input field
        setValue("question", "");
        createChatSession({ chatbot_id: chatbotId, question: data?.question });
      } else {
        const newMessage = {
          role: "user",
          message: data?.question,
          files: null,
        };

        // Update the local message state
        setMessage((prevMessages: any) => [...prevMessages, newMessage]);

        // Clear the input field
        setValue("question", "");

        createChat({
          chatbot_id: chatbotId,
          session_id: sessionId,
          question: data?.question,
        });
      }
    }
  };
  useEffect(() => {
    if (uploadFileInfo?.isSuccess) {
      trainBot({
        chatbot_id: chatbotId,
      });
    }
  }, [uploadFileInfo?.isSuccess]);
  useEffect(() => {
    if (trainBotInfo?.isSuccess) {
      setMessage((prevMessages: any) => [
        ...prevMessages,
        {
          role: "user",
          message: `Uploaded: ${uploadedFile.name}`,
          files: [{ name: uploadedFile.name }],
        },
      ]);
      setUploadedFile(null);
      setIsFileSending(false);
      setValue("question", "");
    }
  }, [trainBotInfo?.isSuccess]);
  useEffect(() => {
    if (createChatInfo?.isSuccess) {
      setValue("question", "");
      getChatHistory({ chatbot_id: chatbotId, session_id: sessionId });
    }
  }, [createChatInfo?.isSuccess]);
  useEffect(() => {
    getChatSession({ chatbot_id: chatbotId });
    scrollToBottom();

    calCardHeight();

    const resizeObserver = new ResizeObserver(() => {
      calCardHeight();
    });

    // Observe changes in the TextField or its container height
    const textFieldContainer = document.querySelector(".textFieldContainer"); // Add a class to the TextField container
    if (textFieldContainer) {
      resizeObserver.observe(textFieldContainer);
    }

    resizeObserver.observe(document.body);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);
  useEffect(() => {
    if (getChatHistoryInfo?.isSuccess) {
      setShowInitialLoader(false);
      const filteredData = filterMessages(
        getChatHistoryInfo?.data?.["chat-history"]
      );
      setMessage((prev: any) => [prev, ...filteredData]);
    }
  }, [getChatHistoryInfo?.isSuccess]);

  const chatContainerRef = useRef<HTMLDivElement | null>(null); // Ref for chat container
  useEffect(() => {
    const handleScroll = () => {
      const container = chatContainerRef.current;
      if (container) {
        if (container) {
          const isAtBottom =
            container.scrollHeight -
              container.scrollTop -
              container.clientHeight <=
            200; // Add a 10px tolerance
          setIsScrolledUp(!isAtBottom); // Show arrow only when not at the bottom
        }
      }
    };

    const container = chatContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
    setIsScrolledUp(false);
  };

  useEffect(() => {
    // Scroll to bottom whenever messages update
    scrollToBottom();
  }, [message]);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setUploadedFile(file);
    }
  };
  useEffect(() => {
    if (uploadedFile) {
      setValue("question", uploadedFile.name);
    }
  }, [uploadedFile]);

  const calCardHeight = () => {
    let targetCompo,
      targetElement: any,
      elementHeight: number,
      tableHeight: number;
    targetCompo = window.document.querySelector<HTMLElement>(".chatInnerCard");
    targetElement = targetCompo?.getBoundingClientRect().y ?? 0;
    elementHeight = window.innerHeight;

    // Dynamically adjust based on text field height
    const textFieldContainer = document.querySelector<HTMLElement>(
      ".textFieldContainer"
    );
    const textFieldHeight = textFieldContainer?.offsetHeight || 0;

    tableHeight = elementHeight - (targetElement + textFieldHeight + 97); // Add textField height dynamically
    setInnerCardHeight(tableHeight);
  };
  const readAloud = (text: string) => {
    // Stop any ongoing speech
    if (window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel();
    }

    // Function to sanitize text
    const sanitizeText = (input: string) => {
      return input.replace(/[\{\}\[\]\\_\^\$&%#~*`]/g, " ");
    };

    // Split text into manageable chunks
    const sentences = sanitizeText(text).split(/(?<=[.!?])\s+/);

    // Speak each chunk sequentially
    const speakChunk = (chunk: string, index: number) => {
      if (index < sentences.length) {
        const utterance = new SpeechSynthesisUtterance(chunk);
        utterance.lang = "en-US";
        utterance.rate = 1;
        utterance.pitch = 1;
        utterance.volume = 1;

        // Speak the current chunk and proceed to the next
        utterance.onend = () => {
          if (index + 1 < sentences.length) {
            speakChunk(sentences[index + 1], index + 1);
          } else {
            setIsSpeaking(false); // Reset the icon when speech ends
          }
        };

        utterance.onerror = (event) => {
          console.error("Speech synthesis error:", event.error);
          setIsSpeaking(false);
        };

        window.speechSynthesis.speak(utterance);
      }
    };

    // Start speaking the first chunk
    if (sentences.length > 0) {
      setIsSpeaking(true);
      speakChunk(sentences[0], 0);
    }
  };

  const stopSpeech = () => {
    if (window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel();
    }
    setIsSpeaking(false);
  };
  const copyText = async (text: any) => {
    try {
      await navigator.clipboard.writeText(text);
      dispatch(
        setAlert({
          open: true,
          alerType: "success",
          message: "Copied to clipboard",
        })
      );
    } catch (error) {
      console.error("Failed to copy text: ", error);
    }
  };
  useEffect(() => {
    if (getChatSessionInfo?.isSuccess) {
      setShowInitialLoader(false);
      if (getChatSessionInfo?.data.length === 0) {
        const newMessage = {
          role: "bot",
          message: `Welcome to ${chatbotName}. I am ${chatbotName}. How can I help you?`,
          files: null,
        };
        setMessage([newMessage]);
      }
      const groupedSessions = groupSessionsByMonth(
        getChatSessionInfo?.data?.sessions
      );
      setSessionList(groupedSessions);
    }
  }, [getChatSessionInfo?.isSuccess, getChatSessionInfo?.fulfilledTimeStamp]);
  useEffect(() => {
    if (createChatSessionInfo?.isSuccess) {
      setNewDialog(false);
      setSessionId(createChatSessionInfo?.data?.session_id);
      getChatSession({ chatbot_id: chatbotId });
      createChat({
        chatbot_id: chatbotId,
        session_id: createChatSessionInfo?.data?.session_id,
        question: question,
      });
    }
  }, [createChatSessionInfo?.isSuccess]);
  useEffect(() => {
    if (sessionList.length > 0) {
      const recentSession = sessionList[0]?.sessions[0]?._id;
      setSessionId(recentSession);
      setShowInitialLoader(true);
      getChatHistory({ chatbot_id: chatbotId, session_id: recentSession });
    }
  }, [sessionList]);
  const handleNewDialog = () => {
    const newMessage = {
      role: "bot",
      message: `Welcome to ${chatbotName}. I am ${chatbotName}. How can I help you?`,
      files: null,
    };
    setMessage([newMessage]);
    setNewDialog(true);
  };
  const handleSessionClick = (id: any) => {
    setSessionId(id);
  };
  useEffect(() => {
    if (sessionId) {
      setShowInitialLoader(true);
      getChatHistory({ chatbot_id: chatbotId, session_id: sessionId });
    }
  }, [sessionId]);
  const handleChange = (e: any) => {
    getChatSession({ chatbot_id: chatbotId, search: e?.target?.value });
  };
  const handleDelete = (e: any, id: any) => {
    e.stopPropagation();
    deleteSession({
      chatbot_id: chatbotId,
      session_id: id,
    });
    setShowInitialLoader(true);
  };
  useEffect(() => {
    if (deleteSessionInfo?.isSuccess) {
      getChatSession({ chatbot_id: chatbotId });
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Session deleted successfully",
        })
      );
      setShowInitialLoader(false);
    }
  }, [deleteSessionInfo?.isSuccess]);
  useEffect(() => {
    if (deleteSessionInfo?.isError) {
      dispatch(
        setAlert({
          open: true,
          alertType: "error",
          message: "Error in delete session",
        })
      );
      setShowInitialLoader(false);
    }
  }, [deleteSessionInfo?.isError]);
  return (
    <>
      {/* <Loader loader={showInitialLoader} /> */}

      <Drawer
        variant="persistent"
        sx={{
          width: "281px",
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: "281px",
            boxSizing: "border-box",
            zIndex: 1,
            // marginTop: "80px",
            padding: "10px",
            height: "100%",
            background: "rgba(122, 114, 225, 0.3)",
          },
        }}
        open={openDrawer}
      >
        <Grid container spacing={2}>
          {/* <Grid size={12}>
                  <Typography className="chatInterface">
                    Chat Interface
                  </Typography>
                </Grid> */}
          
           {chatLoading ? (
                        <Skeleton animation="wave" variant="text" width="100%" height={30} />
                      ) : (
          <Grid size={12} display={"flex"} justifyContent={"space-between"}>
            <Typography className="chat">Chats</Typography>
            <MenuOpenIcon
              sx={{ alignSelf: "baseline", cursor: "pointer" }}
              onClick={() => setOpenDrawer(false)}
            />
          </Grid>
                      )}

          <Grid size={12}>
          {chatLoading ? (
                        <Skeleton animation="wave" variant="text" width="100%" height={30} />
                      ) : (
            <SEARCHBAR onChange={handleChange} />
                      )}
          </Grid>
          <Grid size={12}>
            {/* <Grid size={12}>
                            <Typography className="date">
                              21 Nov 2024
                            </Typography>
                          </Grid> */}

            {sessionList.map((group: any) => (
              <Grid container spacing={1}>
                <div
                  key={group.month}
                  style={{ marginBottom: "20px", width: "100%" }}
                >
                  {/* Month Heading */}
                  <Grid size={12}>
                  {chatLoading ? (
                        <Skeleton animation="wave" variant="text" width="100%" height={30} />
                      ) : (
                        <Typography className="date">{group.month}</Typography>
                      )}

           
                   
                  </Grid>

                  {/* Sessions for the Month */}

                  {group.sessions.map((session: any) => (
                      
                    <>
                     {chatLoading ? (
                        <Skeleton animation="wave" variant="text" width="100%" height={30} />
                      ) : (
                    
                    <Grid size={12} key={session._id} marginBottom={"5px"}>
                      <Typography
                        className={
                          sessionId === session._id
                            ? "selectedSearch"
                            : "searchText"
                        }
                        onClick={() => handleSessionClick(session._id)}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {session.title.replace(/^"|"$/g, "")}
                        <DeleteOutlinedIcon
                          sx={{
                            color:
                              sessionId === session._id ? "white" : "#767676",
                            width: "20px",
                            height: "20px",
                          }}
                          onClick={(e) => handleDelete(e, session?._id)}
                        />
                      </Typography>
                    </Grid>
                      )}
                    </>

                  ))}
                </div>
              </Grid>
            ))}
          </Grid>
          <Grid size={12} position={"absolute"} bottom={"0px"} right={"10px"}>
            {/* <Grid container spacing={2}>
                    <Grid size={12}>
                      <SelectInput placeholder={"AI Model"} optionValue={[]} />
                    </Grid>
                    <Grid size={12} display={"flex"}>
                      <img src={checkbox}></img>{" "}
                      <Typography className="resourceLink">
                        Show resource-link
                      </Typography>
                    </Grid>
                    <Grid size={12} display={"flex"}>
                      <img src={checkbox}></img>
                      <Typography className="resourceLink">
                        {" "}
                        Show proposed prompt
                      </Typography>
                    </Grid>
                  </Grid> */}
          </Grid>
        </Grid>
      </Drawer>
      <Grid
        size={9}
        position={"relative"}
        marginLeft={openDrawer ? "249px" : "0px"}
        width={openDrawer ? " calc(100% - 250px)" : "100%"}
        height={"100%"}
      >
        <Grid container spacing={2}>
          <Grid size={12} display={"flex"}>
            {!openDrawer && (
              <MenuIcon
                sx={{ cursor: "pointer", alignSelf: "center" }}
                onClick={() => setOpenDrawer(true)}
              />
            )}
            <Typography className="chatLogo">{chatbotName}</Typography>
          </Grid>
          <div className="chatInnerCard">
            <Grid
              size={12}
              overflow={"auto"}
              height={`${innerCardHeight}px`}
              ref={chatContainerRef}
            >
              <div
                style={{
                  padding: "20px 20px 0px 20px",
                  margin: "0 auto",
                }}
              >
                {message.map((msg: any, index: any) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent:
                        msg.role === "user" ? "flex-end" : "flex-start",
                      marginBottom: "10px",
                    }}
                  >
                    <div
                      style={{
                        maxWidth: msg.role === "user" ? "70%" : "76%",
                        // backgroundColor:
                        //   msg.role === "user" ? "#DCF8C6" : "#F1F0F0",
                        padding: "0px 15px",
                        // borderRadius: "10px",
                        borderBottomRightRadius:
                          msg.role === "user" ? "0px" : "10px",
                        borderBottomLeftRadius:
                          msg.role === "bot" ? "0px" : "10px",
                        // boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                        textAlign: "left",
                        background:
                          msg.role === "user" ? "#d5d5d540" : undefined,
                        borderRadius: msg.role === "user" ? "10px" : undefined,
                        paddingLeft: msg.role === "user" ? "15px" : "150px",
                      }}
                      className={
                        msg.role === "user" || message.length - 1 === index
                          ? ""
                          : "hoverClass"
                      }
                    >
                      <p
                        style={{
                          margin: 0,
                          color: msg.role === "user" ? "#888888" : "#000000",
                          fontSize: "18px",
                          fontWeight: 400,
                          fontFamily: "Poppins",
                        }}
                        className={msg.role === "user" ? "markPara" : ""}
                      >
                        <ReactMarkdown>{msg.message}</ReactMarkdown>
                      </p>
                      {msg.role === "bot" && (
                        <span
                          style={{
                            width: "100%",
                            display: "flex",
                            height: "25px",
                          }}
                        >
                          {isSpeaking ? (
                            <StopCircleOutlinedIcon
                              sx={{
                                color: "#0000007d",
                                cursor: "pointer",
                                width: "20px",
                                height: "20px",
                              }}
                              onClick={() => stopSpeech()}
                            />
                          ) : (
                            <VolumeUpOutlinedIcon
                              sx={{
                                color: "#0000007d",
                                cursor: "pointer",
                                width: "20px",
                                height: "20px",
                              }}
                              onClick={() => readAloud(msg.message)}
                            />
                          )}
                          <ContentCopyOutlinedIcon
                            sx={{
                              color: "#0000007d",
                              cursor: "pointer",
                              width: "20px",
                              height: "20px",
                            }}
                            onClick={() => copyText(msg.message)}
                          />
                        </span>
                      )}
                      {msg.files && msg.files.length > 0 && (
                        <div style={{ marginTop: "8px" }}>
                          {msg.files.map((file: any, fileIndex: any) => (
                            <a
                              key={fileIndex}
                              href="#"
                              style={{
                                display: "block",
                                color: "#007BFF",
                                textDecoration: "none",
                                fontSize: "14px",
                              }}
                            >
                              {file.name}
                            </a>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Grid>
          </div>
          {/* Typing Animation */}

          <Grid size={12}>
            <Grid container sx={{ marginBottom: "10px", paddingLeft: "35px" }}>
              <Grid size={4}>
                {createChatInfo?.isLoading && (
                  <div
                    style={{
                      maxWidth: "22%",
                      padding: "10px 15px",
                      borderBottomRightRadius: "10px",
                      borderBottomLeftRadius: "0px",
                      backgroundColor: "#f1f1f1",
                    }}
                  >
                    <div className="typing-animation">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                )}
              </Grid>
              <Grid size={4} display={"flex"} justifyContent={"center"}>
                {isScrolledUp && (
                  <div
                    style={{
                      position: "absolute",
                      backgroundColor: "#f1f1f1",
                      borderRadius: "57%",
                      padding: "4px",
                      boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                      cursor: "pointer",
                      zIndex: 10,
                      verticalAlign: "middle",
                      bottom: "25px",
                    }}
                    onClick={scrollToBottom}
                  >
                    <ArrowDownwardIcon style={{ color: "#888888" }} />
                  </div>
                )}
              </Grid>
              <Grid size={4} display={"flex"} justifyContent={"end"}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleNewDialog}
                >
                  <img src={clear}></img>{" "}
                  <Typography className="newDialog">New dialog</Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid size={12} position={"absolute"} bottom={"-33px"} left={"10px"}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                render={({
                  field: { ref, ...field },
                  fieldState: { invalid, error },
                }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    placeholder="Send a message"
                    className="textFieldContainer"
                    maxRows={5}
                    minRows={1}
                    multiline={true}
                    value={field.value}
                    error={Boolean(error)}
                    onChange={field.onChange}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        handleSubmit(onSubmit)();
                      }
                    }}
                    // fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton component="label">
                            <img src={attachIcon} alt="attach" />
                            <input
                              type="file"
                              hidden
                              onChange={handleFileChange}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <>
                          {/* <InputAdornment position="end">
                          <IconButton>
                            <img src={mic}></img>
                          </IconButton>
                        </InputAdornment> */}
                          <InputAdornment position="end">
                            <IconButton type="submit" disabled={isFileSending}>
                              <img
                                src={isFileSending ? loading : send}
                                alt="send"
                                width={"25px"}
                                height={"25px"}
                              />
                            </IconButton>
                          </InputAdornment>
                        </>
                      ),
                    }}
                    sx={{
                      borderRadius: "8px",
                      // marginLeft: "112px",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "8px",
                        padding: "4px",
                        minHeight: "48px",
                        border: "1px solid #E7E7E7",
                      },
                      "& .MuiInputBase-root": {
                        backgroundColor: "#fff",
                      },
                    }}
                  />
                )}
                name="question"
                control={control}
              />
            </form>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
