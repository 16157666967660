import React, { useState, useEffect } from "react";
import { Tabs, Tab, Skeleton } from "@mui/material";
import { Apps, FAQs, Files, SpreadSheets, Links } from "./AllFiles";

export const Sources: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <>
     {/*  {loading ? (
        <Skeleton animation="wave" variant="rectangular" width="100%" height={48} />
      ) : ( */}
        <Tabs value={selectedTab} onChange={handleChange}>
          <Tab label="Links" />
          <Tab label="Files" />
          <Tab label="FAQs" />
          <Tab label="Apps" />
          <Tab label="SpreadSheet" />
        </Tabs>
      {/* )} */}
     {/*  {loading ? (
        <Skeleton animation="wave" variant="rectangular" width="100%" height={400} />
      ) : ( */}
        <>
          {selectedTab === 0 && <Links />}
          {selectedTab === 1 && <Files />}
          {selectedTab === 2 && <FAQs />}
          {selectedTab === 3 && <Apps />}
          {selectedTab === 4 && <SpreadSheets />}
        </>
      {/* )} */}
    </>
  );
};