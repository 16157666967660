import React, { useState, useEffect } from "react";
import { Box, Chip, Paper, Switch, Typography, Skeleton } from "@mui/material";
import Button from "../../../../common/button";
import Grid from "@mui/material/Grid2";
import { Table } from "../../../../common/table";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../../../redux/features/alert/alertSlice";
import { Input } from "../../../../common/input";
import AddIcon from "@mui/icons-material/Add";
import { SelectInput } from "../../../../common/selectInput";
import SyncIcon from "@mui/icons-material/Sync";
import "../../style.css";

export const Links: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const columns: any = [
    {
      field: "links",
      headerName: "Links",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: any) => <p className="slice flex">{params?.value}</p>,
    },
    {
      field: "visibility",
      headerName: "Visibility",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      cellClassName: "cell-padding",
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => <Switch defaultChecked />,
    },
  ];

  const options = [
    { text: "off", value: "off" },
    { text: "every 2 days", value: "every 2 days" },
    { text: "every weeks", value: "every weeks" },
    { text: "every 2 weeks", value: "every 2 weeks" },
    { text: "every 4 weeks", value: "every 4 weeks" },
  ];

  const trainedColumn: any = [
    {
      field: "links",
      headerName: "Links",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: any) => <p className="slice flex">{params?.value}</p>,
    },
    {
      field: "visibility",
      headerName: "Visibility",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      cellClassName: "cell-padding",
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => <Switch defaultChecked />,
    },
    {
      field: "character",
      headerName: "Characters",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      cellClassName: "cell-padding",
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => <p className="slice flex">{params?.value}</p>,
    },
    {
      field: "lastSynced",
      headerName: "Last Synced",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      cellClassName: "cell-padding",
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => <Switch defaultChecked />,
    },
    {
      field: "autoSync",
      headerName: "Auto Sync",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      cellClassName: "cell-padding",
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => (
        <SelectInput
          placeholder={"Select AI Model"}
          fullWidth
          value={"off"}
          optionValue={options}
        />
      ),
    },
    {
      field: "action",
      headerAlign: "left",
      align: "left",
      minWidth: 150,
      cellClassName: "cell-padding",
      sortable: false,
      flex: 1.5,
      renderCell: (params: any) => <SyncIcon />,
    },
  ];

  return (
    <Box sx={{ padding: "20px" }}>
      <Grid container>
        <Grid size={6}>
          <Paper sx={{ padding: "25px" }}>
            {loading ? (
              <>
                <Skeleton animation="wave" variant="text" width="60%" height={30} />
                <Skeleton animation="wave" variant="text" width="80%" height={20} />
                <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
                <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
              </>
            ) : (
              <>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "600",
                    letterSpacing: "-0.08399999886751175px",
                    color: "#000",
                    marginBottom: "6px",
                  }}
                >
                  Crawl Webpages
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#61646B",
                    marginBottom: "12px",
                  }}
                >
                  Provide us with a top-level domain, and we'll identify all
                  linked pages.
                </Typography>
                <Grid container spacing={2}>
                  <Grid size={9}>
                    <Input />
                  </Grid>
                  <Grid size={3}>
                    <Button type="primary" text="Continue" />
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
        </Grid>
        <Grid size={6}>
          <Paper sx={{ padding: "25px" }}>
            {loading ? (
              <>
                <Skeleton animation="wave" variant="text" width="60%" height={30} />
                <Skeleton animation="wave" variant="text" width="80%" height={20} />
                <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
                <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
              </>
            ) : (
              <>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "600",
                    letterSpacing: "-0.08399999886751175px",
                    color: "#000",
                    marginBottom: "6px",
                  }}
                >
                  Submit a XML sitemap
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#61646B",
                    marginBottom: "12px",
                  }}
                >
                  We'll conduct a comprehensive crawl of your entire website.
                </Typography>
                <Grid container spacing={2}>
                  <Grid size={9}>
                    <Input />
                  </Grid>
                  <Grid size={3}>
                    <Button type="primary" text="Load Sitemap" />
                  </Grid>
                </Grid>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>
      <Grid container marginTop={"10px"}>
        <Paper sx={{ padding: "25px", width: "100%" }}>
          {loading ? (
            <>
              <Skeleton animation="wave" variant="text" width="60%" height={30} />
              <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
              <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
            </>
          ) : (
            <>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: "600",
                  letterSpacing: "-0.08399999886751175px",
                  color: "#000",
                  marginBottom: "6px",
                }}
              >
                Add Individual Links
              </Typography>
              <Input />
              <Grid size={12} marginTop={"10px"}>
                <Grid container>
                  <Grid size={10} alignContent={"center"}>
                    <span
                      style={{
                        color: " #5447a9",
                        fontSize: "14px",
                        fontFamily: "Inter",
                        display: "flex",
                      }}
                    >
                      <AddIcon sx={{ width: "16px", height: "16px" }} /> Add more
                    </span>
                  </Grid>
                  <Grid size={2}>
                    <Button type={"primary"} text="submit" />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </Grid>
      {loading ? (
        <Skeleton animation="wave" variant="text" width="100%" height={150} />
      ) : (
        <Paper sx={{ marginTop: "20px" }}>
          <Table
            rows={[]}
            columns={columns}
            isPagination={true}
            rowCount={10}
          />
        </Paper>
      )}
    </Box>
  );
};