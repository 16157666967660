import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import { useState } from "react";
export const Input = (props: any) => {
  const {
    autoFocus,
    disabled,
    onKeyDown,
    customInput,
    label,
    name,
    onBlur,
    onChange,
    type,
    placeholder,
    required,
    value,
    error,
    helperText,
    testid,
    multiline,
    maxRows,
    minRows,
    slotProps,
    inputRef,
  } = props;

  return (
    <>
      <InputLabel error={error} disabled={disabled}>
        {label}
      </InputLabel>

      <FormControl sx={{ width: "100%" }}>
        <TextField
          inputRef={inputRef}
          className={customInput && customInput}
          autoFocus={autoFocus}
          variant="outlined"
          data-testid={testid}
          disabled={disabled}
          error={error}
          fullWidth={true}
          name={name}
          onBlur={onBlur}
          multiline={multiline}
          onChange={onChange}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          autoComplete="off"
          value={value}
          type={type}
          maxRows={maxRows}
          minRows={minRows}
          slotProps={slotProps}
          sx={{
            "& .MuiOutlinedInput-root": {
              height: "40px",
            },
          }}
        />
        <FormHelperText className={`helperClass`}>{helperText}</FormHelperText>
      </FormControl>
    </>
  );
};
export const PasswordInput = (props: any) => {
  const {
    disabled,
    error,
    required,
    label,
    onChange,
    value,
    helperText,
    placeholder,
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <>
      <InputLabel
        className={"inputLabel"}
        htmlFor="bootstrap-input"
        // required={required}
        error={error}
        disabled={disabled}
      >
        {label}
      </InputLabel>

      <FormControl variant="standard" sx={{ width: "100%" }}>
        <TextField
          type={showPassword ? "text" : "password"}
          value={value}
          sx={{
            "& .MuiOutlinedInput-root": {
              height: "40px",
            },
          }}
          placeholder={placeholder}
          error={error}
          onChange={onChange}
          variant="outlined"
          fullWidth
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label={
                      showPassword
                        ? "hide the password"
                        : "display the password"
                    }
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            },
          }}
        />
        <FormHelperText className="helperClass">{helperText}</FormHelperText>
      </FormControl>
    </>
  );
};
