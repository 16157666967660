import { useEffect, useState } from "react";
import { Typography, Box, Skeleton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import "./style.css";
import { animation, slider2 } from "../../../assets/images";
import Buttons from "../../common/button";
import { Controller, useForm } from "react-hook-form";
import { RegExpressions } from "../../../constants/regExp";
import { Input, PasswordInput } from "../../common/input";
import { useLoginUserMutation } from "../../../redux/services/loginApiSlice";
import { useDispatch } from "react-redux";
import { setAuthInfo, setLogin } from "../../../redux/features/auth/authSlice";
import SignUp from "./signUp";
import { LoginHeader } from "../../layout/loginHeader";
import { useNavigate } from "react-router-dom";
import { Page } from "../../../routes/config";
import { setAlert } from "../../../redux/features/alert/alertSlice";
import { Loader } from "../../common/loader";
import { handleErrors } from "../../../utils";
import dayjs from "dayjs";
import ImageSlider from "./imageSlider";

export const SignIn = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signUp, setSignUp] = useState(false);
  const [loginUser, loginUserInfo] = useLoginUserMutation<any>();
  const [loading, setLoading] = useState(false);

  const onSubmit = (data: any) => {
    loginUser(data);
  };

  useEffect(() => {
    if (loginUserInfo?.isSuccess) {
      const { access_token, user_id } = loginUserInfo?.data;
      dispatch(
        setAuthInfo({
          accessToken: access_token,
          userId: user_id,
        })
      );
      dispatch(
        setAlert({
          open: true,
          alertType: "success",
          message: "Logged in successfully",
        })
      );
      dispatch(setLogin(true));
      navigate(Page.DASHBOARD);
    }
  }, [loginUserInfo?.isSuccess]);

  useEffect(() => {
    if (loginUserInfo?.isError) {
      handleErrors(loginUserInfo, dispatch);
    }
  }, [loginUserInfo?.isError]);
  /* 
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 200);
  }, []); */

  return (
    <>
      <Loader loader={loginUserInfo?.isLoading} />
      <Grid container sx={{ height: "100vh" }}>
        <Grid size={12}>
          {loading ? (
            <Skeleton animation="wave" variant="rectangular" height={64} />
          ) : (
            <LoginHeader />
          )}
        </Grid>
        <Grid
          size={12}
          sx={{
            overflow: "auto",
            height: "100%",
            marginTop: "8px",
          }}
        >
          <Grid container spacing={4} height={"100%"}>
            {/* Left Section */}
            <Grid
              size={{ xs: 12, md: 7 }}
              sx={{
                background: "#F9F9FF",
                padding: "35px 115px",
                paddingBottom: "20px",
              }}
            >
              {loading ? (
                <>
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="60%"
                    height={40}
                  />
                  <Skeleton
                    animation="wave"
                    variant="text"
                    width="80%"
                    height={30}
                  />
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width="100%"
                    height={300}
                  />
                </>
              ) : (
                <>
                  <Typography className="heading">
                    {signUp ? "Join Virat Today!" : "Welcome to Virat!"}
                  </Typography>
                  <Typography className="secondaryText">
                    {signUp
                      ? "Build chatbots, analyze enterprise data, and automate workflows with powerful AI tools at your fingertips."
                      : "You are one step away from creating awesome chatbots and automate workflows with powerful AI tools"}
                  </Typography>
                  <ImageSlider images={[animation, slider2, animation]} />
                </>
              )}
            </Grid>

            {/* Right Section */}
            <Grid
              size={{ xs: 12, md: 5 }}
              sx={{
                padding: "18px 100px",
                paddingBottom: signUp ? "7rem" : "20px",
              }}
            >
              <Grid container justifyContent={"center"}>
                <Grid size={12}>
                  {signUp ? (
                    <SignUp signUp={signUp} setSignUp={setSignUp} />
                  ) : (
                    <Box
                      sx={{
                        textAlign: "left",
                      }}
                    >
                      {loading ? (
                        <>
                          <Skeleton
                            animation="wave"
                            variant="text"
                            width="40%"
                            height={40}
                          />
                          <Skeleton
                            animation="wave"
                            variant="rectangular"
                            width="100%"
                            height={200}
                          />
                        </>
                      ) : (
                        <>
                          <Typography className="signIn">Sign In</Typography>
                          {/* Sign-In Form */}
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <Controller
                              render={({
                                field: { ref, ...field },
                                fieldState: { invalid, error },
                              }) => (
                                <>
                                  <Input
                                    {...field}
                                    label="Email Id"
                                    fullWidth
                                    required={true}
                                    helperText={
                                      error?.message ? error?.message : " "
                                    }
                                    value={field.value}
                                    error={Boolean(error)}
                                    onChange={field.onChange}
                                  />
                                </>
                              )}
                              name="email"
                              control={control}
                              rules={{
                                required: "Please enter your email Id",
                                pattern: {
                                  value: RegExpressions?.Email,
                                  message: "Please enter valid email",
                                },
                              }}
                            />
                            <Controller
                              render={({
                                field: { ref, ...field },
                                fieldState: { invalid, error },
                              }) => (
                                <>
                                  <PasswordInput
                                    {...field}
                                    label="Password"
                                    value={field.value}
                                    error={Boolean(error)}
                                    required={true}
                                    onChange={field.onChange}
                                    helperText={
                                      error?.message ? error?.message : ""
                                    }
                                    variant="standard"
                                    fullWidth
                                  />
                                </>
                              )}
                              name="password"
                              control={control}
                              rules={{
                                required: "Please enter your password",
                              }}
                            />
                            <Buttons
                              variant="contained"
                              type="primary"
                              sx={{
                                marginTop: "64px",
                                height: "45px",
                              }}
                              text={"Sign In"}
                              buttonType={"submit"}
                            ></Buttons>
                          </form>
                          <Typography
                            variant="body2"
                            sx={{ marginTop: "1rem", color: "#696969" }}
                          >
                            New to Virat?{" "}
                            <span
                              style={{ color: "#2200FF", cursor: "pointer" }}
                              onClick={() => setSignUp(true)}
                            >
                              Create new Account
                            </span>
                          </Typography>
                        </>
                      )}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid size={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px 60px",
              position: "absolute",
              bottom: "0px",
              width: "100%",
              backgroundColor: "white",
              boxShadow: "0px 1px 2px 0px #0000004D",
            }}
          >
            <span className="bookmark">
              &copy; {dayjs().format("YYYY")} Virat
            </span>
            <span className="bookmark">
              About &nbsp;&nbsp; Support &nbsp;&nbsp; Contact Us
            </span>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
