import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: null,
  userId: null,
  isLoggedIn: false,
  header: "Dashboard",
};

const authSlice: any = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthInfo: (state, { payload: { accessToken, userId } }) => {
      state.userId = userId;
      state.accessToken = accessToken;
    },
    setLogin: (state, { payload }) => {
      state.isLoggedIn = payload;
    },
    setHeader: (state, { payload }) => {
      state.header = payload;
    },
    clearAuthState: (state) => initialState,
  },
  extraReducers: (builder) => {},
});

export const { setAuthInfo, setLogin, clearAuthState, setHeader } =
  authSlice.actions;
export default authSlice.reducer;
export const getAuthState = (state: any) => state?.auth;
export const getIsLoggedIn = (state: any) => state?.auth?.isLoggedIn;
