import Grid from "@mui/material/Grid2";
import { Input } from "../../common/input";
import { Box, Skeleton, Switch } from "@mui/material";
import { bot } from "../../../assets/images";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { useGetWidgetsQuery } from "../../../redux/services/botApiSlice";
import { Loader } from "../../common/loader";
import MessagingContent from "../assistant/messaging";
import StarterQuestionContent from "../assistant/starterQuestion";
import EmailSetupContent from "../assistant/emailSetup";
import WorkingHoursContent from "./workingHours";
import UsageSecurityContent from "./usageSecurity";
import UserFormContent from "./userForm";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Page } from "../../../routes/config";

export const General = (props: any) => {
  const { control, setValue, isLoading } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const botName = useSelector((state: any) => state?.assistant?.name);

  return (
    <Grid container>
      <Grid
        size={12}
        sx={{
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: "600",
          letterSpacing: "-0.08399999886751175px",
          color: "#000",
          marginBottom: "32px",
        }}
      >
        {isLoading ? (
          <Skeleton variant="text" animation="wave" width="60%" height={30} />
        ) : (
          "Configure your assistant according to your needs"
        )}
      </Grid>
      <Grid
        size={12}
        sx={{
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: "600",
          letterSpacing: "-0.08399999886751175px",
          color: "#000",
          marginBottom: "6px",
        }}
      >
        {isLoading ? (
          <Skeleton variant="text" animation="wave" width="40%" height={20} />
        ) : (
          "Assistant name"
        )}
      </Grid>
      <Grid
        size={12}
        sx={{
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: "500",
          color: "#61646B",
          marginBottom: "6px",
        }}
      >
        {isLoading ? (
          <Skeleton variant="text" animation="wave" width="80%" height={20} />
        ) : (
          "Give your assistant a friendly name. Only for internal reference."
        )}
      </Grid>
      <Grid size={12}>
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="100%"
            height={40}
          />
        ) : (
          <Controller
            render={({
              field: { ref, ...field },
              fieldState: { invalid, error },
            }) => (
              <Input
                {...field}
                fullWidth
                required={true}
                helperText={error?.message ? error?.message : " "}
                value={field.value}
                error={Boolean(error)}
                onChange={field.onChange}
              />
            )}
            name="name"
            control={control}
            rules={{
              required: "Please enter assistant name",
            }}
          />
        )}
      </Grid>
      <Grid
        size={12}
        sx={{
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: "600",
          letterSpacing: "-0.08399999886751175px",
          color: "#000",
          marginTop: "40px",
          marginBottom: "6px",
        }}
      >
        {isLoading ? (
          <Skeleton variant="text" animation="wave" width="40%" height={20} />
        ) : (
          "Description"
        )}
      </Grid>
      <Grid
        size={12}
        sx={{
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: "500",
          color: "#61646B",
          marginBottom: "6px",
        }}
      >
        {isLoading ? (
          <Skeleton variant="text" animation="wave" width="80%" height={20} />
        ) : (
          "Assistant description for internal references"
        )}
      </Grid>
      <Grid size={12}>
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="100%"
            height={40}
          />
        ) : (
          <Controller
            render={({
              field: { ref, ...field },
              fieldState: { invalid, error },
            }) => (
              <Input
                {...field}
                fullWidth
                required={true}
                helperText={error?.message ? error?.message : " "}
                value={field.value}
                error={Boolean(error)}
                onChange={field.onChange}
              />
            )}
            name="description"
            control={control}
            rules={{
              required: "Please enter assistant description",
            }}
          />
        )}
      </Grid>
      <Grid
        size={12}
        marginBottom={"15px"}
        onClick={() => navigate(Page.SOURCES)}
        sx={{ cursor: "pointer" }}
      >
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            animation="wave"
            width="100%"
            height={40}
          />
        ) : (
          <div
            style={{
              background: "rgba(122, 114, 225, 0.3)",
              display: "flex",
              padding: "10px",
              fontSize: "14px",
              fontFamily: "Inter",
              alignContent: "center",
            }}
          >
            <InfoOutlinedIcon
              sx={{
                color: "#7a72e1",
                verticalAlign: "middle",
                width: "15px",
                height: "15px",
                marginRight: "10px",
              }}
            />
            Please add files in Sources tab.
          </div>
        )}
      </Grid>
    </Grid>
  );
};
export const Appearance = (props: any) => {
  const {
    selectedColor,
    setSelectedColor,
    selectedWidget,
    setSelectedWidget,
    control,
  } = props;
  const colors = [
    "#B14646", // Red
    "#CC7849", // Orange
    "#DFB419", // Yellow
    "#A2CC49", // Light Green
    "#49CC95", // Green
    "#2FA4C9", // Light Blue
    "#2F6CC9", // Blue
    "#BA84E4", // Purple
    "#E4849B", // Pink
    "colorPicker", // Placeholder for the color picker
  ];
  const [widget, setWidget] = useState([]);
  const [customColor, setCustomColor] = useState("#000");
  const { data, isLoading, isSuccess, isError } = useGetWidgetsQuery<any>({});

  useEffect(() => {
    if (isSuccess) {
      setWidget(data?.message?.icons);
    }
  }, [isSuccess]);

  const handleWidget = (x: any) => {
    setSelectedWidget(x?.icon_name);
  };

  const handleCustomColorChange = (event: any) => {
    const color = event.target.value;
    setCustomColor(color);
    setSelectedColor(color);
  };

  const handleColorSelect = (color: any) => {
    setSelectedColor(color);
  };

  return (
    <>
      {/*   <Loader loader={isLoading} /> */}
      <Grid container>
        <Grid
          size={12}
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "-0.08399999886751175px",
            color: "#000",
            marginBottom: "32px",
          }}
        >
          {isLoading ? (
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={40}
            />
          ) : (
            "Customize the appearance of your assistant as per your needs"
          )}
        </Grid>
        <Grid
          size={12}
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "-0.08399999886751175px",
            color: "#000",
            marginBottom: "6px",
          }}
        >
          {isLoading ? (
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={40}
            />
          ) : (
            "Assistant name on the widget"
          )}
        </Grid>
        <Grid
          size={12}
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "500",
            color: "#61646B",
            marginBottom: "6px",
          }}
        >
          {isLoading ? (
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={40}
            />
          ) : (
            "Assistant name to be displayed in the chatbot"
          )}
        </Grid>
        <Grid size={12}>
          {isLoading ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height={56}
            />
          ) : (
            <Controller
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <Input
                  {...field}
                  fullWidth
                  helperText={error?.message ? error?.message : " "}
                  value={field.value}
                  error={Boolean(error)}
                  onChange={field.onChange}
                />
              )}
              name="name"
              control={control}
            />
          )}
        </Grid>
        <Grid
          size={12}
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "-0.08399999886751175px",
            color: "#000",
            marginBottom: "6px",
            marginTop: "40px",
          }}
        >
          {isLoading ? (
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={40}
            />
          ) : (
            "Subheading (optional)"
          )}
        </Grid>
        <Grid
          size={12}
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "500",
            color: "#61646B",
            marginBottom: "6px",
          }}
        >
          {isLoading ? (
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={40}
            />
          ) : (
            "Chatbot Subheading to be displayed in the chatbot"
          )}
        </Grid>
        <Grid size={12}>
          {isLoading ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width="100%"
              height={56}
            />
          ) : (
            <Controller
              render={({
                field: { ref, ...field },
                fieldState: { invalid, error },
              }) => (
                <Input
                  {...field}
                  fullWidth
                  helperText={error?.message ? error?.message : " "}
                  value={field.value}
                  error={Boolean(error)}
                  onChange={field.onChange}
                />
              )}
              name="subheading"
              control={control}
            />
          )}
        </Grid>
        {/* <Grid
          size={12}
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "-0.08399999886751175px",
            color: "#000",
            marginBottom: "6px",
            marginTop: "42px",
          }}
        >
          {loading ? (
            <Skeleton animation="wave" variant="text" width="100%" height={40} />
          ) : (
            "Virat branding on the widget"
          )}
        </Grid>
        <Grid
          size={12}
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "500",
            color: "#61646B",
            marginBottom: "6px",
          }}
        >
          {loading ? (
            <Skeleton animation="wave" variant="text" width="100%" height={40} />
          ) : (
            "Company Logo to be displayed on the chatbot header"
          )}
        </Grid>
        <Grid
          size={12}
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "-0.08399999886751175px",
            color: "#000",
            marginBottom: "6px",
            marginTop: "42px",
          }}
        >
          {loading ? (
            <Skeleton animation="wave" variant="text" width="100%" height={40} />
          ) : (
            "Assistant Avatar"
          )}
        </Grid>
        <Grid
          size={12}
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "500",
            color: "#61646B",
            marginBottom: "6px",
          }}
        >
          Chatbot Picture to be displayed in the chatbot
        </Grid> */}
        <Grid
          size={12}
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "-0.08399999886751175px",
            color: "#000",
            marginBottom: "6px",
            marginTop: "42px",
          }}
        >
          {isLoading ? (
            <Skeleton
              animation="wave"
              variant="text"
              width="100%"
              height={40}
            />
          ) : (
            "Accent Colour"
          )}
        </Grid>
        <Grid size={12}>
          <Grid container spacing={2}>
            {colors.map((color, index) => (
              <Grid key={index}>
                {isLoading ? (
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={40}
                    height={40}
                  />
                ) : color === "colorPicker" ? (
                  <input
                    type="color"
                    value={customColor}
                    onChange={handleCustomColorChange}
                    style={{
                      width: 40,
                      height: 40,
                      border: "none",
                      padding: 0,
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <Box
                    sx={{
                      width: 40,
                      height: 40,
                      borderRadius: 2,
                      background: color,
                      border:
                        selectedColor === color
                          ? "2px solid #7a72e1"
                          : "1px solid #ddd",
                      cursor: "pointer",
                      transition: "0.3s",
                      "&:hover": {
                        boxShadow: "0px 0px 5px rgba(0,0,0,0.5)",
                      },
                    }}
                    onClick={() => handleColorSelect(color)}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid
          size={12}
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "-0.08399999886751175px",
            color: "#000",
            marginBottom: "6px",
            marginTop: "42px",
          }}
        >
          Widget Icon
        </Grid>
        <Grid
          size={12}
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "500",
            color: "#61646B",
            marginBottom: "6px",
          }}
        >
          Choose the icon for the widget
        </Grid>
        <Grid size={12} display={"flex"}>
          {widget.map((x: any) => {
            return (
              <div
                style={{
                  marginRight: "15px",
                  border:
                    selectedWidget === x?.icon_name
                      ? "2px solid #7a72e1"
                      : "1px solid #ddd",
                  padding: "5px",
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
                onClick={() => handleWidget(x)}
              >
                <img
                  style={{ width: "28px", height: "28px" }}
                  src={x?.icon_url}
                ></img>
              </div>
            );
          })}
        </Grid>
        {/* <Grid
          size={12}
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "-0.08399999886751175px",
            color: "#000",
            marginBottom: "6px",
            marginTop: "42px",
          }}
        >
          Widget position
        </Grid>
        <Grid
          size={12}
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "500",
            color: "#61646B",
            marginBottom: "6px",
          }}
        >
          Choose the location of the widget <br></br>
          Note: Doesn't work on the preview shown on this page
        </Grid>
        <Grid size={12} marginTop={"13px"}>
          <img src={left} style={{ marginRight: "10px" }}></img>
          <img src={right}></img>
        </Grid>
        <Grid size={12} marginTop={"5px"}>
          <span style={{ marginRight: "100px", marginLeft: "37px" }}>Left</span>
          <span> Right</span>
        </Grid> */}
        {/* <Grid
          size={12}
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "-0.08399999886751175px",
            color: "#000",
            marginBottom: "6px",
            marginTop: "42px",
          }}
        >
          Input Placeholder
        </Grid>
        <Grid
          size={12}
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "500",
            color: "#61646B",
            marginBottom: "6px",
          }}
        >
          Chatbot Input Placeholder to be displayed in the chatbot
        </Grid>
        <Grid size={12}>
          <Input placeholder={"Send a message..."} />
        </Grid> */}

        {/* <Grid size={8}>
          <Grid
            size={12}
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "600",
              letterSpacing: "-0.08399999886751175px",
              color: "#000",
              marginBottom: "6px",
              marginTop: "42px",
            }}
          >
            Virat branding on the widget
          </Grid>
          <Grid
            size={12}
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
              color: "#61646B",
              marginBottom: "6px",
            }}
          >
            Hide/Show Virat banner at the bottom
          </Grid>
        </Grid>
        <Grid size={4} marginTop={"42px"}>
          <Switch defaultChecked />
        </Grid>
        <Grid size={8}>
          <Grid
            size={12}
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "600",
              letterSpacing: "-0.08399999886751175px",
              color: "#000",
              marginBottom: "6px",
              marginTop: "42px",
            }}
          >
            Widget is open by default
          </Grid>
          <Grid
            size={12}
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
              color: "#61646B",
              marginBottom: "6px",
            }}
          >
            Open/Close widget when user engage for the first time
          </Grid>
        </Grid>
        <Grid size={4} marginTop={"42px"}>
          <Switch defaultChecked />
        </Grid>
        <Grid size={8}>
          <Grid
            size={12}
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "600",
              letterSpacing: "-0.08399999886751175px",
              color: "#000",
              marginBottom: "6px",
              marginTop: "42px",
            }}
          >
            Starter Questions
          </Grid>
          <Grid
            size={12}
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "500",
              color: "#61646B",
              marginBottom: "6px",
            }}
          >
            Show floating Starter Questions
          </Grid>
        </Grid>
        <Grid size={4} marginTop={"42px"}>
          <Switch defaultChecked />
        </Grid> */}
      </Grid>
    </>
  );
};

export const EmailSetup = () => <EmailSetupContent />;

export const UsageSecurity = () => <UsageSecurityContent />;

export const UserForm = () => <UserFormContent />;

export const WorkingHours = () => <WorkingHoursContent />;
