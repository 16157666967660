import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Buttons from "../common/button";
import { useDispatch } from "react-redux";
import { clearAlertState } from "../../redux/features/alert/alertSlice";
import { clearAssistantState } from "../../redux/features/assistant/assistantSlice";
import { clearAuthState } from "../../redux/features/auth/authSlice";

export const Logout = (props: any) => {
  const { open, setOpen } = props;
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    dispatch(clearAlertState());
    dispatch(clearAssistantState());
    dispatch(clearAuthState());
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to logout?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Buttons
          text={"No"}
          autoFocus={true}
          type="secondary"
          onClick={handleClose}
        />
        <Buttons text={"Yes"} type="primary" onClick={handleLogout} />
      </DialogActions>
    </Dialog>
  );
};
