import React, { useEffect, useState } from "react";
import { Box, Chip, Skeleton, Typography } from "@mui/material";
import Button from "../../../../common/button";
import Grid from "@mui/material/Grid2";
import { Table } from "../../../../common/table";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../../../redux/features/alert/alertSlice";
import { deleteIcon } from "../../../../../assets/icons";
import { Loader } from "../../../../common/loader";
import { useGetFilesQuery, useTrainBotMutation, useUploadFileMutation } from "../../../../../redux/services/botApiSlice";

export const Files: React.FC = () => {
  const [files, setFiles] = useState<any[]>([]);
  const [rowData, setRowData] = useState<any[]>([]);
  const formData = new FormData();
  const chatbotId = useSelector((state: any) => state?.assistant?.id);
  const dispatch = useDispatch();

  const [uploadFile, uploadFileInfo] = useUploadFileMutation<any>();
  const [trainBot, trainBotInfo] = useTrainBotMutation<any>();
  const { data, isSuccess, isLoading, isError, isFetching, refetch } = useGetFilesQuery<any>({ id: chatbotId });

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    const filesArray = Array.from(droppedFiles);
    setFiles((prev) => [...prev, ...filesArray]);
    console.log("Files dropped:", droppedFiles);
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    const filesArray = Array.from(selectedFiles!);
    setFiles((prev) => [...prev, ...filesArray]);
    console.log("Files selected:", selectedFiles);
  };

  const handleUpload = () => {
    console.log("Uploading files:", files);
    formData.append("chatbot_id", chatbotId);
    if (files.length > 0) {
      files.forEach((file) => {
        formData.append(`files`, file);
      });
    }
    uploadFile(formData);
  };

  useEffect(() => {
    if (uploadFileInfo?.isSuccess) {
      trainBot({ chatbot_id: chatbotId });
      dispatch(setAlert({ open: true, alertType: "success", message: "File uploaded successfully" }));
    }
  }, [uploadFileInfo?.isSuccess]);

  useEffect(() => {
    if (trainBotInfo?.isSuccess) {
      dispatch(setAlert({ open: true, alertType: "success", message: "Assistant trained successfully" }));
      setFiles([]);
      refetch();
    }
  }, [trainBotInfo?.isSuccess]);

  const handleDeleteFile = (index: number) => {
    setFiles((prev) => prev.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (isSuccess) {
      const updatedFileNames = data?.file_names.map((file: any, index: any) => ({
        ...file,
        id: index + 1,
      }));
      setRowData(updatedFileNames);
    }
  }, [isSuccess, data]);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: any) => <p className="slice">{params?.value}</p>,
    },
    {
      field: "type",
      headerName: "Type",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: any) => <p className="slice">{params?.row?.name.split(".")[1]}</p>,
    },
    {
      field: "date_trained",
      headerName: "Date",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: any) => <p className="slice">{params?.value}</p>,
    },
    {
      field: "tokens_consumed",
      headerName: "Token Consumed",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: any) => <p className="slice">{params?.value === 0 ? "" : params?.value}</p>,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "left",
      cellClassName: "cell-padding",
      align: "left",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params: any) => <Chip label={"Trained"} className={"enableChip"} />,
    },
  ];

  return (
    <>
      <Box sx={{ padding: "20px" }}>
      {isLoading || isFetching ? (
              <Skeleton animation="wave" variant="rectangular" width="30%" height={20} />
            ) : (
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "-0.08399999886751175px",
            color: "#000",
            marginBottom: "6px",
          }}
        >
          
          Upload files to train Assistant on your data
        </Typography>
            )}
        {isLoading || isFetching ? (
              <Skeleton animation="wave" variant="rectangular" width="100%" height={200} />
            ) : (
        <Box
          sx={{
            border: "2px dashed #dcdcdc",
            borderRadius: "8px",
            padding: "20px",
            backgroundColor: "#f4f6f8",
            color: "#555",
            height: "150px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "10px",
          }}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          <Typography>
            Drag and drop files here or{" "}
            <label style={{ cursor: "pointer", color: "#3f51b5" }}>
              click to upload
              <input type="file" multiple hidden onChange={handleFileSelect} />
            </label>
          </Typography>
        </Box>
            )}
        <Grid container>
          {files.length > 0 &&
            files.map((x, index) => (
              <Grid size={12} key={index}>
                {x?.name}
                <img
                  style={{ cursor: "pointer", marginLeft: "20px" }}
                  src={deleteIcon}
                  alt="Delete"
                  onClick={() => handleDeleteFile(index)}
                />
              </Grid>
            ))}
        </Grid>
        <Grid container>
          <Grid size={2}>
          {isLoading || isFetching ? (
              <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
            ) : (
            <Button
              type="primary"
              sx={{ marginTop: "20px" }}
              onClick={handleUpload}
              disabled={files.length === 0}
              text={"Upload and train"}
            />
            )}
          </Grid>
        </Grid>
        <Grid container sx={{ border: "1px solid #DDDDE2" }} marginTop={5}>
          <Grid
            size={12}
            sx={{
              borderBottom: "1px solid #DDDDE2",
              padding: "10px 20px",
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: "600",
              letterSpacing: "-0.08399999886751175px",
              color: "#000",
            }}
          >
            {isLoading || isFetching ? (
              <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
            ) : (
            "Files"
            )}
          </Grid>
          <Grid size={12}>
            {isLoading || isFetching ? (
              <Skeleton animation="wave" variant="rectangular" width="100%" height={400} />
            ) : (
              <div className="botTable">
                <Table rows={rowData} columns={columns} isPagination={true} rowCount={10} />
              </div>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};