import React, { useState, useEffect } from "react";
import {
  Switch,
  Typography,
  Skeleton,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Input } from "../../../../common/input";
import Button from "../../../../common/button";
import { useNavigate } from "react-router-dom";

export const CreateGuideline: React.FC = () => {
  const [makeActive, setMakeActive] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);
    return () => clearTimeout(timer);
  }, []);

  const handleInstruction = () => {
    setMakeActive(!makeActive);
  };

  const navigate = useNavigate();
  const handleGuideline = () => {
    navigate("/assistant/guidelines/");
  };

  return (
    <Grid container sx={{ position: "relative", height: "100%" }}>
      <Grid size={8}>
        <Grid
          container
          sx={{ margin: "1rem 0" }}
          display={"flex"}
          justifyContent={"space-between"}
        >
          {loading ? (
            <Skeleton animation="wave" variant="text" width="60%" height={30} />
          ) : (
            <Typography variant="body1" className="heading">
              Customize your bot's behaviour with your own instructions
            </Typography>
          )}
          <Grid size={3} display={"flex"} justifyContent={"flex-end"}>
            {loading ? (
              <Skeleton animation="wave" variant="rectangular" width="100px" height={40} />
            ) : (
              <>
                <Switch checked={makeActive} onChange={handleInstruction} />
                <Typography sx={{ fontWeight: 600, paddingTop: "0.4rem" }}>
                  Make active
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Grid size={12}>
            {loading ? (
              <Skeleton animation="wave" variant="text" width="40%" height={20} />
            ) : (
              <Typography className="heading">Title</Typography>
            )}
          </Grid>
          <Grid size={12}>
            {loading ? (
              <Skeleton animation="wave" variant="text" width="60%" height={20} />
            ) : (
              <Typography variant="caption">
                For reference only and doesn't affect your bot's behaviour
              </Typography>
            )}
          </Grid>
          <Grid size={12}>
            {loading ? (
              <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
            ) : (
              <Input />
            )}
          </Grid>
        </Grid>
        <Grid container>
          <Typography className="heading" sx={{ paddingTop: "1.25rem" }}>
            Guideline
          </Typography>
        </Grid>
        <Grid size={12}>
          {loading ? (
            <Skeleton animation="wave" variant="rectangular" width="100%" height={100} />
          ) : (
            <Input multiline={true} minRows={4} />
          )}
        </Grid>
        <Grid sx={{ marginTop: "2rem" }}>
          {loading ? (
            <Skeleton animation="wave" variant="text" width="80%" height={20} />
          ) : (
            <Typography variant="body2">
              Use @ to add in Action to your Guideline. You can only add 1
              action per guideline.
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid
        size={12}
        sx={{
          position: "absolute",
          bottom: "0.125rem",
          right: "2rem",
          left: "0",
          paddingTop: "0.5rem",
          borderTop: "1px solid var(--bot-border-color)",
        }}
      >
        <Grid display={"flex"} justifyContent={"flex-end"}>
          <Grid size={2} display={"flex"}>
            {loading ? (
              <>
                <Skeleton animation="wave" variant="rectangular" width="100px" height={40} />
                <Skeleton animation="wave" variant="rectangular" width="100px" height={40} />
              </>
            ) : (
              <>
                <Button
                  text="Cancel"
                  type="secondary"
                  sx={{ marginRight: "0.5rem" }}
                  onClick={handleGuideline}
                />
                <Button text="Save" type="primary" />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};