import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { List, ListItem, ListItemText, ListItemIcon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Page } from "../../routes/config";
import "./style.css";
import {
  blackSettings,
  chart,
  connect,
  equalizer,
  home,
  integration,
  message,
  rightArrow,
  settings,
  share,
  whiteChart,
  whiteConnect,
  whiteIntegration,
  whiteMessage,
} from "../../assets/icons";
import {
  clearAssistantState,
  setAssistantSidebar,
} from "../../redux/features/assistant/assistantSlice";
import { bot } from "../../assets/images";
import { clearAlertState } from "../../redux/features/alert/alertSlice";
import { clearAuthState, setHeader } from "../../redux/features/auth/authSlice";
import { Logout } from "./logOut";

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sidebarState = useSelector((state: any) => state?.assistant?.create);
  const botName = useSelector((state: any) => state?.assistant?.name);
  const botIcon = useSelector((state: any) => state?.assistant?.icon);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    const pathname = location.pathname;
    switch (pathname) {
      case Page.DASHBOARD:
        dispatch(setAssistantSidebar(false));
        dispatch(setHeader("Dashboard"));
        break;
      case Page.SETTINGS:
        dispatch(setHeader("Settings"));
        break;
      case Page.GUIDELINES:
        dispatch(setHeader("Guidelines"));
        break;
      case Page.SOURCES:
        dispatch(setHeader("Sources"));
        break;
      case Page.CONNECT:
        dispatch(setHeader("Connect"));
        break;
      case Page.INTEGRATION:
        dispatch(setHeader("Integration"));
        break;
      case Page.ACTIONS:
        dispatch(setHeader("Actions"));
        break;
      case Page.INBOX:
        dispatch(setHeader("Inbox"));
        break;
      case Page.ANALYTICS:
        dispatch(setHeader("Analytics"));
        break;
      default:
        break;
    }
  }, [location.pathname]);

  const handleLogout = () => {
    setOpen(true);
  };

  return (
    <div className="sideBarMain">
      <List component="nav" sx={{ height: "100%" }}>
        <div
          style={{
            display: sidebarState ? "flex" : "grid",
            cursor: sidebarState ? "pointer" : undefined,
          }}
          className="logoContainer"
          onClick={() => navigate(Page.DASHBOARD)}
        >
          {sidebarState ? (
            <>
              <img src={botIcon} alt="bot" />
              <span className="logo" style={{ marginLeft: "5px" }}>
                {botName}
              </span>
            </>
          ) : (
            <>
              <span className="logo">Virat</span>
              <span className="logoSub">
                Private workspace
                <img className="arrowImg" src={rightArrow} alt="arrow" />
              </span>
            </>
          )}
        </div>
        <div className="scrollSidebar">
          {sidebarState ? (
            <div className="sidebarItemsContainer">
              <ListItem
                style={{
                  background: "transparent",
                  borderRadius: "6px",
                }}
              >
                <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }}>
                  <img src={share} alt="share" />
                </ListItemIcon>
                <ListItemText
                  primary="Training"
                  className="listTextCss"
                  sx={{
                    color: " #4f4f4f",
                  }}
                />
              </ListItem>
              <ListItem
                component={Link}
                to={Page.SOURCES}
                style={{
                  background:
                    location?.pathname === Page.SOURCES
                      ? " #7A72E1"
                      : "transparent",
                  borderRadius: "6px",
                }}
              >
                <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }} />
                <ListItemText
                  primary="Sources"
                  className="listTextCss"
                  sx={{
                    color:
                      location?.pathname === Page.SOURCES
                        ? "white"
                        : " #4f4f4f",
                  }}
                />
              </ListItem>
              {/* <ListItem
                component={Link}
                to={Page.GUIDELINES}
                style={{
                  background: location?.pathname.includes(Page.GUIDELINES)
                    ? " #7A72E1"
                    : "transparent",
                  borderRadius: "6px",
                }}
              >
                <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }} />
                <ListItemText
                  primary="Guidelines"
                  className="listTextCss"
                  sx={{
                    color: location?.pathname.includes(Page.GUIDELINES)
                      ? "white"
                      : " #4f4f4f",
                  }}
                />
              </ListItem> */}
              {/* <ListItem>
                <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }} />
                <ListItemText primary="Workflows" className="listTextCss" />
              </ListItem> */}
              {/* <ListItem
                component={Link}
                to={Page.ACTIONS}
                style={{
                  background: location?.pathname.includes(Page.ACTIONS)
                    ? " #7A72E1"
                    : "transparent",
                  borderRadius: "6px",
                }}
              >
                <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }} />
                <ListItemText
                  primary="Actions"
                  className="listTextCss"
                  sx={{
                    color: location?.pathname.includes(Page.ACTIONS)
                      ? "white"
                      : " #4f4f4f",
                  }}
                />
              </ListItem> */}
              <ListItem
                component={Link}
                to={Page.SETTINGS}
                style={{
                  background:
                    location?.pathname === Page.SETTINGS
                      ? " #7A72E1"
                      : "transparent",
                  borderRadius: "6px",
                }}
              >
                <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }}>
                  {location?.pathname === Page.SETTINGS ? (
                    <img src={settings} alt="settings" />
                  ) : (
                    <img src={blackSettings} alt="blackSettings" />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Settings"
                  className="listTextCss"
                  sx={{
                    color:
                      location?.pathname === Page.SETTINGS
                        ? "white"
                        : " #4f4f4f",
                  }}
                />
              </ListItem>
              <ListItem
                component={Link}
                to={Page.INTEGRATION}
                style={{
                  background:
                    location?.pathname === Page.INTEGRATION
                      ? " #7A72E1"
                      : "transparent",
                  borderRadius: "6px",
                }}
              >
                <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }}>
                  {location?.pathname === Page.INTEGRATION ? (
                    <img src={whiteIntegration} alt="whiteIntegration" />
                  ) : (
                    <img src={integration} alt="integration" />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Integrations"
                  className="listTextCss"
                  sx={{
                    color:
                      location?.pathname === Page.INTEGRATION
                        ? "white"
                        : " #4f4f4f",
                  }}
                />
              </ListItem>
              <ListItem
                component={Link}
                to={Page.CONNECT}
                style={{
                  background:
                    location?.pathname === Page.CONNECT
                      ? " #7A72E1"
                      : "transparent",
                  borderRadius: "6px",
                }}
              >
                <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }}>
                  {location?.pathname === Page.CONNECT ? (
                    <img src={whiteConnect} alt="whiteConnect" />
                  ) : (
                    <img src={connect} alt="connect" />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Connect"
                  className="listTextCss"
                  sx={{
                    color:
                      location?.pathname === Page.CONNECT
                        ? "white"
                        : " #4f4f4f",
                  }}
                />
              </ListItem>
              <ListItem
                component={Link}
                to={Page.INBOX}
                style={{
                  background:
                    location?.pathname === Page.INBOX
                      ? " #7A72E1"
                      : "transparent",
                  borderRadius: "6px",
                }}
              >
                <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }}>
                  {location?.pathname === Page.INBOX ? (
                    <img src={whiteMessage} alt="whiteMessage" />
                  ) : (
                    <img src={message} alt="message" />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Inbox"
                  className="listTextCss"
                  sx={{
                    color:
                      location?.pathname === Page.INBOX ? "white" : " #4f4f4f",
                  }}
                />
              </ListItem>
              <ListItem
                component={Link}
                to={Page.ANALYTICS}
                style={{
                  background:
                    location?.pathname === Page.ANALYTICS
                      ? " #7A72E1"
                      : "transparent",
                  borderRadius: "6px",
                }}
              >
                <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }}>
                  {location?.pathname === Page.ANALYTICS ? (
                    <img src={whiteChart} />
                  ) : (
                    <img src={chart}></img>
                  )}
                </ListItemIcon>
                <ListItemText
                  primary="Analytics"
                  className="listTextCss"
                  sx={{
                    color:
                      location?.pathname === Page.ANALYTICS
                        ? "white"
                        : " #4f4f4f",
                  }}
                />
              </ListItem>
            </div>
          ) : (
            // --------------------------------------regular sidebar---------------------------------------

            <div className="sidebarItemsContainer">
              <ListItem
                component={Link}
                to={Page.DASHBOARD}
                style={{
                  background:
                    location?.pathname === Page.DASHBOARD
                      ? " #7A72E1"
                      : "transparent",
                  borderRadius: "6px",
                }}
              >
                <ListItemIcon sx={{ minWidth: "18px", marginRight: "12px" }}>
                  <img
                    src={home}
                    alt="home"
                    style={{
                      color:
                        location?.pathname === Page.DASHBOARD
                          ? "white"
                          : "black",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Home"
                  className="listTextCss"
                  sx={{
                    color:
                      location?.pathname === Page.DASHBOARD
                        ? "white"
                        : " #4f4f4f",
                  }}
                />
              </ListItem>
            </div>
          )}
          <div className="logoutContainer">
            <ListItem onClick={handleLogout} sx={{ cursor: "pointer" }}>
              <ListItemText primary="Logout" className="listTextCss" />
            </ListItem>
          </div>
        </div>
      </List>
      <Logout open={open} setOpen={setOpen} />
    </div>
  );
};

export default Sidebar;
