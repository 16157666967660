import React, { useState, useEffect } from "react";
import { Typography, Skeleton } from "@mui/material";
import Buttons from "../../../common/button";
import Grid from "@mui/material/Grid2";
import { Table } from "../../../common/table";
import { SEARCHBAR } from "../../../common/search";
import { useNavigate } from "react-router-dom";
import { GridRenderCellParams } from '@mui/x-data-grid';
import { Column } from "../interface";

export const GuidelinesManager: React.FC = () => {
  const [rowData, setRowData] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    page: 0,
  });
  const [search, setSearch] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 700);
    return () => clearTimeout(timer);
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    setPagination({ ...pagination, page: 0 });
  };

  const navigate = useNavigate();
  const handleCreateGuideLines = () => {
    navigate("/assistant/guidelines/create");
  };

  const columns: Column[] = [
    {
      field: "title",
      headerName: "Title",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return <p className="slice flex">Test</p>;
      },
    },
    {
      field: "active",
      headerName: "Active",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return <p className="slice flex">Test</p>;
      },
    },
    {
      field: "action",
      headerName: "Actions",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return <p className="slice flex">Test</p>;
      },
    },
    {
      field: "modified by",
      headerName: "Modified by",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return <p className="slice flex">Test</p>;
      },
    },
    {
      field: "Last modified",
      headerName: "Last modified",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return <p className="slice flex">Test</p>;
      },
    },
  ];

  return (
    <>
      <Grid
        container
        sx={{ margin: "1rem 0 2rem" }}
        display={"flex"}
        justifyContent={"space-between"}
      >
        {loading ? (
          <Skeleton animation="wave" variant="text" width="60%" height={30} />
        ) : (
          <Typography variant="body1" className="heading">
            Fine tune your bot's automatically generated responses by adding
            customized instructions
          </Typography>
        )}
        <Grid  display={"flex"} justifyContent={"flex-end"} size={2}>
          {loading ? (
            <>
              <Skeleton animation="wave" variant="rectangular" width="100px" height={40} />
              <Skeleton animation="wave" variant="rectangular" width="100px" height={40} />
            </>
          ) : (
            <>
              <Buttons
                type="secondary"
                text="Learn more"
                sx={{ marginRight: "1rem" }}
              />
              <Buttons
                type="primary"
                onClick={handleCreateGuideLines}
                start="addIcon"
                text="Add new"
              />
            </>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={8} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid size={4}>
          {loading ? (
            <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
          ) : (
            <SEARCHBAR onChange={handleSearch} value={search} placeholder={"Search by title"} />
          )}
        </Grid>
        <Grid>
          {loading ? (
            <Skeleton animation="wave" variant="text" width="60%" height={20} />
          ) : (
            <Typography variant="caption">
              You can maintain up to 10 active guidelines simultaneously
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid size={12} sx={{ marginTop: '1.25rem' }}>
          {loading ? (
            <Skeleton animation="wave" variant="rectangular" width="100%" height={400} />
          ) : (
            <Table
              rows={rowData}
              columns={columns}
              isPagination={true}
              rowCount={rowCount}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};