import { Button } from "@mui/material";
import "./style.css";
import { upload } from "../../../assets/icons";
import AddIcon from '@mui/icons-material/Add';

const Buttons = (props: any) => {
  const {
    variant,
    disabled,
    className,
    type,
    fullWidth,
    text,
    onClick,
    href,
    endIcon,
    startIcon,
    sx,
    buttonType,
    form,
    start,
    addIcon,
    autoFocus
  } = props;
  const getStartIcon = () => {
    switch (start) {
      case "upload":
        return (
          <img
            src={upload}
            alt="upload icon"
            style={{ width: 16, height: 16 }}
          />
        );
       case "addIcon":
        return (
          <AddIcon />
        )
      default:
        return null;
    }
  };
  return (
    <Button
      form={form}
      data-testid="buttons"
      sx={sx}
      className={className ? `${type} ${className}` : `${type}`}
      variant={variant}
      disabled={disabled}
      fullWidth={true}
      onClick={onClick}
      disableFocusRipple={true}
      href={href}
      type={buttonType}
      data-text={text}
      startIcon={getStartIcon()}
      autoFocus={autoFocus}
    >
      {text}
    </Button>
  );
};

export default Buttons;
