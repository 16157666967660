import React, { useEffect, useState } from "react";
import { Box, Typography, Skeleton } from "@mui/material";
import Button from "../../../../common/button";
import Grid from "@mui/material/Grid2";
import { Table } from "../../../../common/table";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "../../../../../redux/features/alert/alertSlice";
import { deleteIcon } from "../../../../../assets/icons";
import Buttons from "../../../../common/button";

export const FAQs: React.FC = () => {
  const [files, setFiles] = useState<any[]>([]);
  const [rowData, setRowData] = useState<any[]>([]);
  const formData = new FormData();
  const chatbotId = useSelector((state: any) => state?.assistant?.id);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 300); 
    return () => clearTimeout(timer);
  }, []);

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    const filesArray = Array.from(droppedFiles);
    setFiles((prev) => [...prev, ...filesArray]);
    console.log("Files dropped:", droppedFiles);
  };

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;
    const filesArray = Array.from(selectedFiles!);
    setFiles((prev) => [...prev, ...filesArray]);
    console.log("Files selected:", selectedFiles);
  };

  const handleUpload = () => {
    console.log("Uploading files:", files);
    formData.append("chatbot_id", chatbotId);
    if (files.length > 0) {
      files.forEach((file, index) => {
        formData.append(`files`, file);
      });
    }
  };

  const handleDeleteFile = (index: number) => {
    setFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: any) => <p className="slice">{params?.value}</p>,
    },
    {
      field: "updatedAt",
      headerName: "Type",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: any) => (
        <p className="slice">{params?.row?.name.split(".")[1]}</p>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 150,
      headerAlign: "left",
      sortable: false,
      cellClassName: "cell-padding",
      flex: 1,
      renderCell: (params: any) => <p className="slice">{params?.value}</p>,
    },
  ];

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography
        sx={{
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: "600",
          letterSpacing: "-0.08399999886751175px",
          color: "#000",
          marginBottom: "30px",
        }}
      >
        Download our template, add all your FAQs in the sheet, and upload
        instantly
      </Typography>
      <Grid container sx={{ background: "#f2f2f5", padding: "16px 24px" }}>
        <Grid size={10}>
          {loading ? (
            <Skeleton animation="wave" variant="text" width="100%" height={40} />
          ) : (
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "14px",
                fontWeight: "600",
                letterSpacing: "-0.08399999886751175px",
                color: "#000",
                marginBottom: "6px",
              }}
            >
              Download our template, add all your FAQs in the sheet, and upload
              instantly
            </Typography>
          )}
        </Grid>
        <Grid size={2}>
          {loading ? (
            <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
          ) : (
            <Buttons type={"secondary"} text="Download" />
          )}
        </Grid>
      </Grid>

      <Box
        sx={{
          border: "2px dashed #dcdcdc",
          borderRadius: "8px",
          padding: "20px",
          backgroundColor: "#f4f6f8",
          color: "#555",
          height: "150px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "10px",
        }}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {loading ? (
          <Skeleton animation="wave" variant="rectangular" width="100%" height={150} />
        ) : (
          <Typography sx={{ textAlign: "center" }}>
            Drag and drop files here or{" "}
            <label style={{ cursor: "pointer", color: "#3f51b5" }}>
              click to upload
              <input type="file" multiple hidden onChange={handleFileSelect} />
            </label>
            <br />
            Upto 100MB in size. CSV (See template above)
          </Typography>
        )}
      </Box>
      <Grid container>
        {files.length > 0 &&
          files.map((x, index) => (
            <Grid size={12} key={index}>
              {loading ? (
                <Skeleton animation="wave" variant="text" width="100%" height={40} />
              ) : (
                <>
                  {x?.name}
                  <img
                    style={{ cursor: "pointer", marginLeft: "20px" }}
                    src={deleteIcon}
                    alt="Delete"
                    onClick={() => handleDeleteFile(index)}
                  />
                </>
              )}
            </Grid>
          ))}
      </Grid>
      <Grid container>
        <Grid size={2}>
          {loading ? (
            <Skeleton animation="wave" variant="rectangular" width="100%" height={40} />
          ) : (
            <Button
              type="primary"
              sx={{ marginTop: "20px" }}
              onClick={handleUpload}
              disabled={files.length === 0}
              text={"Upload and train"}
            />
          )}
        </Grid>
      </Grid>
      <Grid container sx={{ border: "1px solid #DDDDE2" }} marginTop={5}>
        <Grid
          size={12}
          sx={{
            borderBottom: "1px solid #DDDDE2",
            padding: "10px 20px",
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: "600",
            letterSpacing: "-0.08399999886751175px",
            color: "#000",
          }}
        >
          {loading ? (
            <Skeleton animation="wave" variant="text" width="100%" height={40} />
          ) : (
            "Added FAQs"
          )}
        </Grid>
        <Grid size={12}>
          {loading ? (
            <Skeleton animation="wave" variant="rectangular" width="100%" height={400} />
          ) : (
            <div className="botTable">
              <Table
                rows={rowData}
                columns={columns}
                isPagination={true}
                rowCount={10}
              />
            </div>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};